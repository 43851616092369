import * as React from 'react';
import { CalendarComingSoon } from 'Icons';
import { Wrapper, IconContainer, Text } from './NoOptionsPlaceholder.styles';

export const NoOptionsPlaceholder: React.FC = () => {
  return (
    <Wrapper>
      <IconContainer>
        <CalendarComingSoon />
      </IconContainer>
      <Text>More options coming soon...</Text>
    </Wrapper>
  );
};
