import React from 'react';
import { TextField as MuiTextField } from 'Molecules/TextField';

interface CustomTextFieldProps {
  value: string;
  updateValue: (newValue: unknown) => void;
}

export default function CustomTextField({
  value,
  updateValue,
}: CustomTextFieldProps) {
  return (
    <MuiTextField
      showLabel={false}
      type="text"
      placeholder="Type a text to filter by"
      label=""
      value={value}
      handleChange={(e) => {
        const { value } = e.target;
        updateValue(value as string);
      }}
    />
  );
}
