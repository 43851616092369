import sub from 'date-fns/sub';
import setHours from 'date-fns/setHours';
import startOfWeek from 'date-fns/startOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfWeek from 'date-fns/endOfWeek';
import endOfMonth from 'date-fns/endOfMonth';
import endOfYear from 'date-fns/endOfYear';
import isValid from 'date-fns/isValid';
import addDays from 'date-fns/addDays';
import { DateRange } from 'Shared/types/dashboard';
import {
  LAST_WEEK,
  LAST_MONTH,
  LAST_3_MONTH,
  LAST_6_MONTH,
  LAST_YEAR,
  WHOLE_PROJECT,
  SPECIFY_DATES,
} from 'Pages/dashboard/components/DateFilter/predefinedOptions';

const datesFormatter = (from: Date, to: Date): DateRange => {
  return {
    from: setHours(from, 1),
    to,
  };
};

const getMonthsRange = (fromMonthSub: number, toMonthSub: number) => {
  const fromMonth = sub(new Date(), { months: fromMonthSub });
  const from = startOfMonth(fromMonth);
  const toMonth = sub(new Date(), { months: toMonthSub });
  const to = endOfMonth(toMonth);
  return datesFormatter(from, to);
};

export const wholeProjectDatesFn = (project) => {
  const {
    stage,
    launchDate: projectLaunchDate,
    endDate: projectEndDate,
  } = project;

  const launchDate = new Date(projectLaunchDate);
  const endDate = new Date(projectEndDate);

  const wholeProjectFormat = (finalDate = new Date()) => {
    return { from: launchDate, to: finalDate };
  };

  if (!isValid(launchDate)) return { from: null, to: null };
  if (stage === 'active') {
    const formattedDate = wholeProjectFormat();
    return formattedDate;
  }
  if (stage === 'closed' || stage === 'completed') {
    if (isValid(endDate)) {
      const formattedDate = wholeProjectFormat(addDays(endDate, 1));
      return formattedDate;
    }
  }

  return wholeProjectFormat();
};

export const mapDateFilter = (value: string, project): DateRange => {
  if (value === SPECIFY_DATES) return;
  const optionsMapper = {
    [LAST_WEEK]: () => {
      const lastWeek = sub(new Date(), { weeks: 1 });
      const from = startOfWeek(lastWeek);
      const to = endOfWeek(lastWeek);
      return datesFormatter(from, to);
    },
    [LAST_MONTH]: () => {
      const lastMonth = sub(new Date(), { months: 1 });
      const from = startOfMonth(lastMonth);
      const to = endOfMonth(lastMonth);
      return datesFormatter(from, to);
    },
    [LAST_3_MONTH]: () => {
      const { from, to } = getMonthsRange(3, 1);
      return { from, to };
    },
    [LAST_6_MONTH]: () => {
      const { from, to } = getMonthsRange(6, 1);
      return { from, to };
    },
    [LAST_YEAR]: () => {
      const fromDate = sub(new Date(), { years: 1 });
      const from = startOfYear(fromDate);
      const toDate = sub(new Date(), { years: 1 });
      const to = endOfYear(toDate);
      return datesFormatter(from, to);
    },
    [WHOLE_PROJECT]: () => wholeProjectDatesFn(project),
  }[value];
  const mappedResult = optionsMapper();
  return mappedResult;
};
