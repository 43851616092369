import styled, { css } from 'styled-components';

export const Item = styled.li`
  font-size: 0.875rem;
  opacity: 0.7;
  padding: 0.5rem 1.3125rem;
  cursor: pointer;
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.bold};
    &:hover {
      background-color: ${theme.colors.grey[300]};
    }
  `}
`;
