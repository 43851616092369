import { FilterOption, FilterRow } from 'Shared/types/filters';
import getAvailableAnswers from './getAvailableAnswers';

interface Props {
  filterOptions: FilterOption[];
  additionalFilterable: { [key: string]: string[] };
  allAnswers: { [key: string]: unknown[] };
}

export default function getDefaultFilterRow({
  filterOptions,
  additionalFilterable,
  allAnswers,
}: Props): FilterRow | null {
  try {
    if (!filterOptions.length) return null;
    const firstFilterOption = filterOptions[0];

    const { property: initialProperty, conditions, rules } = firstFilterOption;
    const { conditionConfig } = rules;
    const initialCondition = conditions[0];
    const inputType = conditionConfig[initialCondition];

    const possibleValues = getAvailableAnswers({
      filterOption: firstFilterOption,
      additionalFilterable,
      allAnswers,
    });

    return {
      group: 'AND',
      property: initialProperty,
      condition: initialCondition,
      possibleConditions: conditions,
      possibleValues,
      inputType,
      value: [],
      metadata: {},
    };
  } catch (error) {
    return null;
  }
}
