import * as React from 'react';
import { SummaryFirstRow, SummaryRespondentCounter } from './Title.styles';
import { TitleProps } from '../../types';

export const Title: React.FC<TitleProps> = ({
  counterTotal,
  title,
}: TitleProps) => {
  return (
    <SummaryFirstRow>
      <SummaryRespondentCounter>{counterTotal}</SummaryRespondentCounter>{' '}
      {title}
    </SummaryFirstRow>
  );
};
