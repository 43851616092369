import * as React from 'react';
import { GroupType } from 'Shared/types/group';
import { BlankRadioButtonIcon, GreenRadioButtonIcon } from 'Icons';
import { RadioButtonProps } from '../../types';
import {
  Wrapper,
  RadioItem,
  RadioButton,
  RadioWrapper,
} from './RadioButton.styles';

export const RadioButtons: React.FC<RadioButtonProps> = ({
  groupType,
  setGroupType,
}: RadioButtonProps) => {
  const { DYNAMIC, STATIC } = GroupType;
  const contentToRender = [
    {
      type: DYNAMIC,
      label: 'Dynamic group',
      helperText: 'Future contributions will be added automatically',
    },
    {
      type: STATIC,
      label: 'Static group',
      helperText: 'Will not change automatically',
    },
  ];

  const onGroupSelect = (type: GroupType) => {
    setGroupType(type);
  };

  const iconsRenderer = (isSelected: boolean) => {
    if (isSelected) return <GreenRadioButtonIcon />;
    return <BlankRadioButtonIcon />;
  };

  return (
    <Wrapper data-testid="RadioButtons-Wrapper">
      {contentToRender.map((content) => (
        <RadioItem
          isGroupSelected={groupType === content.type}
          key={content.type}
        >
          <RadioWrapper>
            <RadioButton onClick={() => onGroupSelect(content.type)}>
              {iconsRenderer(groupType === content.type)}
            </RadioButton>
            <span>{content.label}</span>
          </RadioWrapper>
          <p>{content.helperText}</p>
        </RadioItem>
      ))}
    </Wrapper>
  );
};
