import styled, { css } from 'styled-components';

export const ApplyFilterButton = styled.button`
  height: 2.6rem;
  width: 100%;
  border: none;
  cursor: pointer;
  ${({ theme }) => css`
    background-color: ${theme.colors.green[500]};
    color: ${theme.colors.white[500]};
    font-size: ${theme.texts.secondaryText.fontSize};
    font-weight: ${theme.fontWeights.bold};
    &:hover {
      background-color: ${theme.colors.green[600]};
    }
  `}
`;

export const CalendarOptionWrapper = styled.div`
  .react-select__option {
    display: flex;
  }
  svg {
    margin-right: 0.6rem;
    color: ${({ theme }) => theme.colors.green[500]};
  }
`;
