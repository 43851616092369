import * as React from 'react';
import { HiddenColumnsToggle } from 'Pages/dashboard/contributions/components';
import { DropDownListItemProps } from '../../types';
import { ListItem } from './DropDownListItem.styles';

export const DropDownListItem = ({
  checked,
  onToggle,
  column,
}: DropDownListItemProps) => {
  return (
    <ListItem data-testid="DropDownListItem-ListItem">
      <span>{column.columnHeader}</span>
      <HiddenColumnsToggle checked={checked} onToggle={onToggle} />
    </ListItem>
  );
};
