import React from 'react';
import { FilterRow } from 'Shared/types/filters';
import { OptionItem } from 'Client/types';
import CustomSelect from '../CustomSelect/CustomSelect';
import { mapHelperContainerData } from './helpers';
import { ConditionContainerWrapper } from './styles';

interface HelperContainerProps {
  rowIndex: number;
  openToTop: boolean;
  row: FilterRow;
  updateRowMetadata: (
    rowIndex: number,
    keyToUpdate: string,
    newMetadataValue: unknown
  ) => void;
}

export default function HelperContainer({
  rowIndex,
  openToTop,
  row,
  updateRowMetadata,
}: HelperContainerProps) {
  const helperData = mapHelperContainerData(row);
  if (!helperData) return null;
  const { metadataValue, placeholder, updateKey, options, isMulti } =
    helperData;
  const value = (() => {
    if (Array.isArray(metadataValue)) {
      return options.filter((option) => metadataValue.includes(option.value));
    }
    return options.find((option) => option.value === metadataValue) || null;
  })();

  return (
    <ConditionContainerWrapper data-gi="globalFiltersV2-helper-container">
      <CustomSelect
        placeholder={placeholder}
        openToTop={openToTop}
        options={options}
        value={value}
        isMulti={isMulti}
        onChange={(option: OptionItem | OptionItem[]) => {
          if (!option) {
            updateRowMetadata(rowIndex, updateKey, null);
            return;
          }
          if (Array.isArray(option)) {
            const values = option.map((item) => item.value);
            updateRowMetadata(rowIndex, updateKey, values);
            return;
          }
          const { value } = option;
          updateRowMetadata(rowIndex, updateKey, value);
        }}
      />
    </ConditionContainerWrapper>
  );
}
