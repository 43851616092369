import { SortDirection, SortOptions } from 'Shared/types/dashboard';
import { MapSortObject } from 'Pages/dashboard/components/Table/types';
import { formatQuestionsColumnsId } from './formatQuestionsColumnsId';

export const mapSortObject = ({
  currentSort,
  newColumnId,
}: MapSortObject): SortOptions => {
  const formattedColumnId = formatQuestionsColumnsId(newColumnId);
  const defaultSortObject = {
    columnId: formattedColumnId,
    sortDirection: 'asc' as SortDirection,
  };
  if (!currentSort) return defaultSortObject;
  const { columnId, sortDirection } = currentSort;
  if (formattedColumnId === columnId) {
    return {
      columnId,
      sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
    };
  }
  return defaultSortObject;
};
