import { ChartItem } from 'Shared/types/dashboard';

export const enabledContributionsQuestionTypes = [
  'sentiment',
  'select',
  'smilie',
  'rating-number',
  'imagepoll',
  'textpoll',
  'label',
  'text',
  'textarea',
  'priorities',
  'matrix',
  'budget',
];

export const additionalChartData: Array<ChartItem> = [
  {
    id: 'sentiment',
    name: 'feeling',
    label: 'Contributions sentiment',
    type: 'sentiment',
    content: {},
    section: {
      title: 'sentiment',
      showSectionTitle: false,
    },
  },
];
