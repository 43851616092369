import * as React from 'react';
import { useRouter } from 'next/router';
import Select from 'react-select';
import { OptionItem } from 'Client/types';
import { addNewTopFilter, getTopFiltersFromUrl } from 'Pages/dashboard/utils';
import { mapProposals, ALL_PROPOSALS } from './utils';
import { ProposalDropDownProps, ProposalOptionItem } from './types';
import {
  customOptionComponent,
  customMenuComponent,
  formatGroupLabel,
} from './components';
import { Wrapper } from './ProposalDropDown.styles';

export const ProposalDropDown: React.FC<ProposalDropDownProps> = ({
  proposals,
  isLoading,
}: ProposalDropDownProps) => {
  const router = useRouter();
  const topFilters = getTopFiltersFromUrl({ router });
  const proposalFilter = topFilters?.proposals || [];

  const [proposalsOptions, setProposalsOptions] =
    React.useState<ProposalOptionItem[]>();
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [selectedProposal, setSelectedProposal] =
    React.useState<OptionItem>(null);

  const onApplyFilters = () => {
    const proposalSlug = (() => {
      if (selectedProposal.value === ALL_PROPOSALS) return [ALL_PROPOSALS];
      const proposal = proposals.find(
        (proposal) => proposal.title === selectedProposal.value
      );
      return [proposal.slug];
    })();
    addNewTopFilter({
      page: 'contributions',
      router,
      newFilter: { proposals: proposalSlug },
    });
    setIsMenuOpen(false);
  };

  const Option = customOptionComponent();
  const Menu = customMenuComponent({ onApplyFilters });

  React.useEffect(() => {
    if (!proposals) return;
    const options = mapProposals(proposals);
    const defaultValue = options[0]?.options[0] as OptionItem;
    const selectedProposal = (() => {
      if (!proposalFilter.length) return defaultValue;
      if (proposalFilter[0] === ALL_PROPOSALS) return defaultValue;
      const proposal = proposals.find(
        (proposal) => proposal.slug === proposalFilter[0]
      );
      if (!proposal) return defaultValue;
      return {
        value: proposal.title,
        label: proposal.title,
      };
    })();
    setProposalsOptions(options);
    setSelectedProposal(selectedProposal);
  }, [proposals]);

  return (
    <Wrapper>
      <Select
        options={proposalsOptions}
        onChange={(option: OptionItem) => setSelectedProposal(option)}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        classNamePrefix="react-select"
        value={selectedProposal}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        closeMenuOnSelect={false}
        openMenuOnClick={true}
        autoFocus={true}
        blurInputOnSelect={false}
        isMulti={false}
        isLoading={isLoading}
        loadingMessage={() => 'fetching proposals...'}
        isClearable={false}
        isSearchable={false}
        components={{ Option, Menu }}
        formatGroupLabel={formatGroupLabel}
      />
    </Wrapper>
  );
};
