import { FilterRow, FilterOption, UrlFilter } from 'Shared/types/filters';
import { GlobalFiltersProps } from '../types';
import getAvailableAnswers from './getAvailableAnswers';
import mapRowMetadata from './mapRowMetadata';

interface MapUrlToFiltersProps {
  urlFilters: UrlFilter[];
  filterOptions: FilterOption[];
  allAnswers: GlobalFiltersProps['allAnswers'];
  additionalFilterable: GlobalFiltersProps['additionalFilterable'];
}

export default function mapUrlToFilters({
  urlFilters,
  filterOptions,
  additionalFilterable,
  allAnswers,
}: MapUrlToFiltersProps): FilterRow[] {
  return urlFilters
    .map((urlFilter) => {
      const { property, value, filterCondition, filterGroup, metadata } =
        urlFilter;
      const filterOption = filterOptions.find((filterOption) => {
        return filterOption.property === property;
      });

      if (!filterOption) {
        return;
      }

      const {
        conditions,
        rules: { conditionConfig },
      } = filterOption;
      const mappedMetadata = mapRowMetadata(filterOption);

      if (metadata) {
        if (metadata.budget) {
          mappedMetadata['budget']['budgetValue'] = metadata.budget.budgetValue;
        }
        if (metadata.matrix) {
          mappedMetadata['matrix']['topicValue'] = metadata.matrix.topicValue;
        }
        if (metadata.priorities) {
          mappedMetadata['priority']['priorityValue'] =
            metadata.priorities.priorityValue;
        }
      }

      const availableAnswers = getAvailableAnswers({
        filterOption,
        allAnswers,
        additionalFilterable,
        rowMetadata: mappedMetadata,
      });

      return {
        group: filterGroup,
        property,
        condition: filterCondition,
        value,
        possibleConditions: conditions,
        possibleValues: availableAnswers,
        inputType: conditionConfig[filterCondition],
        metadata: mappedMetadata,
      };
    })
    .filter(Boolean);
}
