import * as React from 'react';
import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/browser';
import { useMutation } from '@apollo/client';
import { GroupType } from 'Shared/types/group';
import { useProject } from 'Client/utils/hooks';
import { ErrorStatusOrHelperText } from 'Atoms';
import {
  Header,
  Summary,
  FiltersDisplayer,
  GroupName,
  RadioButtons,
  Footer,
} from './components';
import { hasAppliedFilters, mapDataToGroupInfo } from './utils';
import { CreateGroupProps } from './types';
import { Content, ErrorMessageContainer } from './CreateGroup.styles';
import { CREATE_GROUP_MUTATION } from './CreateGroup.gql';

export const CreateGroup: React.FC<CreateGroupProps> = ({
  filters,
  closeSidePanel,
  currentSelectedRows,
  selectedRowsIds,
  allRows,
  isAllRowsCheckboxSelected,
  isGroupEmail,
  setCreateGroupFeedbackMessage,
  reference,
}: CreateGroupProps) => {
  const { _id: projectId, id: projectName } = useProject();
  const [createRespondentGroup, { loading, data }] = useMutation(
    CREATE_GROUP_MUTATION
  );
  const [groupName, setGroupName] = React.useState<string>('');
  const [groupType, setGroupType] = React.useState<GroupType>(GroupType.STATIC);
  const hasFilters = hasAppliedFilters({ filters });
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const documentsToExclude = isAllRowsCheckboxSelected
    ? allRows.filter((row) => !selectedRowsIds.includes(row))
    : [];

  const [error, setError] = React.useState<{ message: string; exist: boolean }>(
    { message: '', exist: false }
  );
  const [existingGroups, setExistingGroups] = React.useState([]);

  React.useEffect(() => {
    const getGroups = async () => {
      try {
        const result = await fetch('/api/external/group/groupsName', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        const { success, data } = await result.json();

        if (success === false) {
          setExistingGroups([]);
          return;
        }

        const mappedGroups = data.map((group) => ({
          value: group._id,
          name: group.name,
        }));

        setExistingGroups(mappedGroups);
      } catch (error) {
        captureException(`error in getGroups @ GroupsSelect.tsx : ${error}`);
      }
    };

    getGroups();
  }, []);

  const saveGroups = async () => {
    setIsFetching(true);

    try {
      const groupInfo = mapDataToGroupInfo({
        projectId,
        groupName,
        groupType,
        filters,
      });
      const body = {
        groupInfo,
        documentsToExclude: documentsToExclude,
        documentsIds: isAllRowsCheckboxSelected ? [] : selectedRowsIds,
      };
      await fetch('/api/external/group/create', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      if (!isGroupEmail) {
        setCreateGroupFeedbackMessage({
          messageType: 'success',
          groupName: groupName,
          isOpen: true,
        });
      }
      setIsFetching(false);
    } catch (error) {
      captureException(`error in saveGroups @ CreateGroup.tsx : ${error}`);
      setCreateGroupFeedbackMessage({
        messageType: 'error',
        groupName: groupName,
        isOpen: true,
      });
      setIsFetching(false);
    }
  };

  const onRightButtonClick = async () => {
    // Group name validation:
    const maxLength = 50;
    const minLength = 3;

    if (!groupName.length) {
      setError({ message: 'Group name can’t be empty.', exist: true });

      return;
    }

    if (groupName.length > maxLength) {
      setError({
        message: 'Group name can’t be more than 50 characters.',
        exist: true,
      });

      return;
    }

    if (groupName.length < minLength) {
      setError({
        message: 'Group name can’t be less than 3 characters.',
        exist: true,
      });

      return;
    }

    const groupAlreadyExist = existingGroups?.some(
      (group) => group.name.toLowerCase() === groupName.toLowerCase()
    );

    if (groupAlreadyExist) {
      setError({
        message: 'Group name already exists.',
        exist: true,
      });

      return;
    }

    if (!isFetching && reference === 'contribution') {
      await saveGroups();
      closeSidePanel();
      if (isGroupEmail) {
        window.location.href =
          '/v5/dashboard/communications/group-email?ref=contribution';
      }
    } else if (!isFetching && reference === 'respondent') {
      setIsFetching(true);
      createRespondentGroup({
        variables: {
          createGroupInput: {
            reference: 'respondent',
            type: groupType,
            documentsToExclude,
            documentsIds: isAllRowsCheckboxSelected ? [] : selectedRowsIds,
            projectId,
            projectName,
            name: groupName,
            filters,
            isAllRowsCheckboxSelected,
          },
        },
      });
      closeSidePanel();
      if (isGroupEmail) {
        window.location.href =
          '/v5/dashboard/communications/group-email?ref=respondent';
      }
    }
  };

  React.useEffect(() => {
    if (!loading && isFetching && reference === 'respondent') {
      if (data?.createNewGroup?._id) {
        setCreateGroupFeedbackMessage({
          messageType: 'success',
          groupName: groupName,
          isOpen: true,
        });
      } else {
        setCreateGroupFeedbackMessage({
          messageType: 'error',
          groupName: groupName,
          isOpen: true,
        });
      }
      setIsFetching(false);
    }
  }, [loading, data]);

  const showErrorMessage = error?.exist;

  return (
    <>
      <Content data-testid="CreateGroup-Content">
        <Header
          closeSidePanel={() => {
            !isFetching && closeSidePanel();
          }}
        />
        <Summary
          reference={reference}
          contributionsCount={currentSelectedRows}
          hasFilters={hasFilters}
        />
        <FiltersDisplayer filters={filters} hasFilters={hasFilters} />
        <GroupName groupName={groupName} setGroupName={setGroupName} />

        {showErrorMessage && (
          <ErrorMessageContainer>
            <ErrorStatusOrHelperText
              status={{
                type: 'error',
                message: error?.message,
              }}
            />
          </ErrorMessageContainer>
        )}
        <RadioButtons groupType={groupType} setGroupType={setGroupType} />
      </Content>
      <Footer
        leftButtonLabel="Cancel"
        onLeftButtonClick={() => !isFetching && closeSidePanel()}
        rightButtonLabel={
          isGroupEmail ? 'Create Group & email' : 'Create group'
        }
        onRightButtonClick={onRightButtonClick}
        rigthButtonDataOnboarding={
          isGroupEmail
            ? 'dashboard-create-group-email-cta'
            : 'dashboard-create-group-cta'
        }
      />
    </>
  );
};
