import styled, { css } from 'styled-components';

export const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 1rem;
`;

export const GreenButtonWithAddIcon = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  color: ${({ theme }) => theme.colors.green['A700']};
  span {
    align-self: flex-end;
    font-size: 0.9rem;
    font-weight: bold;
  }
  svg {
    stroke-width: 2;
    margin-right: 0.5rem;
    width: 1.25rem;
    height: 1.25rem;
    align-self: center;
    margin: 0;
    padding-top: 0.2rem;
  }
`;

export const ApplyFiltersButton = styled.button`
  font-size: 1.15rem;
  font-weight: bold;
  border: none;
  padding: 0.5rem 0.4rem;
  border-radius: 0.35rem;
  ${({ theme, disabled }) => css`
    color: ${theme.colors.white[500]};
    background: ${disabled ? theme.colors.grey[200] : theme.colors.green[500]};
    cursor: ${disabled ? 'default' : 'pointer'};
  `}
`;
