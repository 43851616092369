import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const LeftSideLabel = styled.h4``;
export const ActionsWrapper = styled.div`
  margin-right: 1rem;
`;
export const HiddenColumnsButton = styled.button`
  padding: 0.5rem 0.9375rem;
  min-width: 11.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  cursor: pointer;
  border-radius: 0.90625rem;
  ${({ theme }) => css`
    color: ${theme.colors.green[500]};
    border: ${`1px solid ${theme.colors.grey[600]}`};
  `}
  span {
    font-size: 0.875rem;
    font-weight: 700;
  }
  svg {
    height: 0.8125rem;
    width: 0.875rem;
    align-self: flex-end;
    transform: scale(1.5);
  }
`;
