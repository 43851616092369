import {
  FilterRow,
  FilterRowMetadata,
  FilterOption,
  UrlFilter,
  FilterOptionQuestionContent,
} from 'Shared/types/filters';

const formatMetadata = (metadata: FilterRowMetadata) => {
  if (!metadata) return undefined;
  const { budget, matrix, priority } = metadata;
  return {
    budget: budget && { budgetValue: budget.budgetValue },
    matrix: matrix && { topicValue: matrix.topicValue },
    priorities: priority && {
      priorityValue: priority.priorityValue,
      priorityMax: priority.priorityMax,
    },
  };
};
const formatType = (
  questionContent: FilterOptionQuestionContent,
  property: string
) => {
  if (!questionContent) return property;
  if (
    questionContent.sensitiveType === 'postcode' ||
    questionContent.type === 'address'
  ) {
    return 'geocode-filter';
  }
  return 'questionAnswer';
};
const formatQuestionId = (questionContent: FilterOptionQuestionContent) => {
  if (!questionContent) return undefined;
  /* 
    demographics questions are the ones with sensitive type
    we need to return the name of the question instead of the questionId
  */
  if ('sensitiveType' in questionContent) {
    return questionContent.name;
  }
  return questionContent.questionId;
};

export default function mapFiltersToUrl(
  filterRows: FilterRow[],
  filterOptions: FilterOption[]
): UrlFilter[] {
  return filterRows.map((filterRow) => {
    const { property, value, condition, group, metadata } = filterRow;
    const filterOption = filterOptions.find((filterOption) => {
      return filterOption.property === property;
    });
    const { questionContent } = filterOption;
    return {
      filterGroup: group,
      type: formatType(questionContent, property),
      questionId: formatQuestionId(questionContent),
      questionType: questionContent ? questionContent.type : undefined,
      property,
      value,
      filterCondition: condition,
      metadata: formatMetadata(metadata),
    };
  });
}
