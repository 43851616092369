import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  svg {
    width: 30rem;
    height: 5rem;
  }
`;

export const Label = styled.span`
  width: 100%;
  text-align: center;
  font-weight: 600;
`;
