import * as React from 'react';
import { EyeIcon, EyeWithLineIcon } from 'Icons';
import { HiddenColumnsToggleProps } from './types';
import { BaseSwitch, Input, IconsWrapper } from './HiddenColumnsToggle.styles';

export const HiddenColumnsToggle: React.FC<HiddenColumnsToggleProps> = ({
  checked,
  onToggle,
}: HiddenColumnsToggleProps) => {
  return (
    <BaseSwitch data-testid="HiddenColumnsToggle-BaseSwitch">
      <Input
        checked={checked}
        type="checkbox"
        onChange={() => {
          onToggle();
        }}
      />
      <IconsWrapper checked={checked}>
        <EyeWithLineIcon />
        <EyeIcon />
      </IconsWrapper>
    </BaseSwitch>
  );
};
