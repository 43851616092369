import * as React from 'react';
import uniqBy from 'lodash.uniqby';
import { useQuery } from '@apollo/client';
import { LoadRing } from 'Molecules';
import { ChartItem } from 'Shared/types/dashboard';
import { ChartsContainer } from 'Pages/dashboard/charts';
import { TableHeader } from 'Client/pages/dashboard/components/TableHeader/TableHeader';
import { ChartsWrapperProps } from './types';
import {
  enabledContributionsQuestionTypes,
  additionalChartData,
} from './constants';
import { GET_QUESTIONS_WITH_NAMES } from './ChartsWrapper.gql';

export const ChartsWrapper: React.FC<ChartsWrapperProps> = ({
  projectId,
  appliedFilters,
  dataTotal,
  filteredRows,
  dataName,
  contributionsIds,
}: ChartsWrapperProps) => {
  const [questions, setQuestions] = React.useState<Array<ChartItem>>([]);

  const { data, loading } = useQuery(GET_QUESTIONS_WITH_NAMES, {
    variables: {
      getQuestionsWithNamesInput: {
        projectId,
        proposalsToFilter: appliedFilters.topFilters.proposals,
        lang: 'en-GB',
      },
    },
  });

  React.useEffect(() => {
    if (data) {
      const { getQuestionsWithNames } = data;

      const mappedQuestions = getQuestionsWithNames
        .map((question) => ({
          id: question.questionId,
          name: question.questionName,
          label: question.label,
          content: question.content,
          type: question.type,
          section: {
            title: question.proposal.title,
            showSectionTitle: true,
          },
          slug: question.proposal.slug,
        }))
        .filter(
          (chartItem) =>
            enabledContributionsQuestionTypes.includes(chartItem.type) &&
            chartItem.id
        );

      const uniqueQuestions = uniqBy(
        [...additionalChartData, ...mappedQuestions],
        'id'
      );

      setQuestions(uniqueQuestions);
    }
  }, [data]);

  if (loading) {
    return <LoadRing loadingMessage="Loading charts" />;
  }

  return (
    <div data-testid="questions-charts">
      <TableHeader
        isTableLoading={loading}
        dataTotal={dataTotal}
        currentlyFilteredRows={filteredRows}
        dataName={dataName}
      />
      <ChartsContainer
        data={questions}
        appliedFilters={appliedFilters}
        dataType={'questions'}
        pageType="contributions"
        contributionsIds={contributionsIds}
      />
    </div>
  );
};
