import { theme } from 'Theme';
import { statusData } from 'Pages/dashboard/contributions/constants';

export const getOptionColor = (optionValue: string): string => {
  const status = statusData.find(
    (status) => status.label.toLowerCase() === optionValue.toLowerCase()
  );
  if (status) return status.color;
  return theme.colors.green['A700'];
};
