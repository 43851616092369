import * as React from 'react';
import { makeRedirectDomain } from 'Client/utils/url';
import { useProject } from 'Client/utils/hooks';
import { StyledButton } from './ButtonItem.styles';
import { ToolTip } from '../ToolTip';
import { CommentReplyProps } from '../../types';

export const CommentReply: React.FC<CommentReplyProps> = ({
  selectedContributions,
  contributions,
}: CommentReplyProps) => {
  const { id } = useProject();
  const [toolTipText, setToolTipText] = React.useState<string>('');
  const isButtonDisabled = React.useMemo(() => {
    const populatedContributions = contributions.filter((contribution) =>
      selectedContributions.includes(contribution._id as string)
    );
    const areAllContributionsConfirmed = populatedContributions.every(
      (contribution) => contribution.status === 'confirmed'
    );
    const allContributionsHaveConsent = populatedContributions.every(
      (contribution) => {
        const stringWithConsents = (contribution.consents as string) || '';
        const consents = stringWithConsents.split(', ');
        return consents.includes('Comment responses');
      }
    );
    const isOnlyOneSelected = selectedContributions.length === 1;
    if (!isOnlyOneSelected) {
      setToolTipText(
        'This is BETA. You can only select one comment to reply to the moment. Multiple replies coming soon!'
      );
      return true;
    }
    if (!areAllContributionsConfirmed) {
      setToolTipText(
        'You can’t reply to contributions when their status is not Confirmed. Please pick another contribution.'
      );
      return true;
    }
    if (!allContributionsHaveConsent) {
      setToolTipText(
        'You can only reply to people that gave the “Comment responses” consent.'
      );
      return true;
    }
    return false;
  }, [selectedContributions, contributions]);

  const onButtonClick = () => {
    if (isButtonDisabled) return;
    const contributionId = selectedContributions[0];
    const baseUrl = makeRedirectDomain(id);
    const url = `http://${baseUrl}/response/${contributionId}` as string;
    window.open(url, '_blank');
  };

  return (
    //only show tooltip if button is disabled
    <ToolTip isDisabled={!isButtonDisabled} text={toolTipText}>
      <StyledButton
        title="CommentReplyButton"
        disabled={isButtonDisabled}
        isLastButton={false}
        onClick={onButtonClick}
      >
        Comment reply
      </StyledButton>
    </ToolTip>
  );
};
