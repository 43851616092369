export const updateQuery = (query, router, newQuery) => {
  Object.keys(newQuery).map((q) => {
    query[q] = encodeURI(newQuery[q]);
  });
  router.push({
    pathname: '/v5/dashboard/respondents',
    query,
  });

  return query;
};

export const updateQueryWithoutReload = (newQuery: {
  [key: string]: string;
}) => {
  const url = new URL(window.location.href);
  Object.keys(newQuery).map((q) => {
    url.searchParams.set(q, encodeURI(newQuery[q]));
  });
  window.history.pushState(null, '', url.toString());
};
