import { gql } from '@apollo/client';

export const GET_LAST_CONTRIBUTION = gql`
  query GetMostRecentContribution(
    $mostRecentContributionInput: MostRecentContributionParams
  ) {
    getMostRecentContribution(
      MostRecentContributionInput: $mostRecentContributionInput
    ) {
      date
    }
  }
`;

export const GET_LAST_RESPONDENT = gql`
  query GetMostRecentRespondent(
    $getMostRecentRespondentInput: MostRecentRespondentInput
  ) {
    getMostRecentRespondent(
      GetMostRecentRespondentInput: $getMostRecentRespondentInput
    ) {
      signedUpDate
    }
  }
`;
