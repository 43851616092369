import * as React from 'react';
import { Waypoint } from 'react-waypoint';
import { TableWrapperProps } from './types';
import { NoSSRTable, TableHeader } from '../';

export const TableWrapper: React.FC<TableWrapperProps> = ({
  baseTableHeaders,
  loading,
  fetching,
  rowsData,
  filteredRows,
  dataTotal,
  hiddenColumns,
  setHiddenColumns,
  setSelectedRowsIds,
  setSelectedRows,
  loadMoreData,
  tableSort,
  setTableSort,
  onAllRowsCheckboxToggle,
  isAllRowsCheckboxSelected,
  setFlatRowsIds,
  dataName,
  tableType,
}: TableWrapperProps) => {
  const [isWaypointInside, setIsWaypointInside] =
    React.useState<boolean>(false);
  const [selectedRowsTableIds, setSelectedRowsTableIds] = React.useState<
    Array<string>
  >([]);

  return (
    <div
      data-onboarding="dashboard-respondents-table"
      data-testid="Table-Wrapper"
    >
      <TableHeader
        isTableLoading={loading}
        dataTotal={dataTotal}
        currentlyFilteredRows={filteredRows}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
        dataName={dataName}
      />
      <NoSSRTable
        baseTableHeaders={baseTableHeaders}
        rowsData={rowsData}
        loading={loading}
        fetching={fetching}
        loadMoreData={loadMoreData}
        tableSort={tableSort}
        setTableSort={setTableSort}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
        setSelectedRowsIds={setSelectedRowsIds}
        setSelectedRows={setSelectedRows}
        setSelectedRowsTableIds={!loading ? setSelectedRowsTableIds : () => ''}
        selectedRowsTableIds={selectedRowsTableIds}
        setFlatRowsIds={setFlatRowsIds}
        onAllRowsCheckboxToggle={onAllRowsCheckboxToggle}
        isAllRowsCheckboxSelected={isAllRowsCheckboxSelected}
        isWaypointInside={isWaypointInside}
        tableType={tableType}
      />
      {!loading && (
        <Waypoint
          data-testId={'Table-Waypoint'}
          onEnter={() => setIsWaypointInside(true)}
          onLeave={() => setIsWaypointInside(false)}
          bottomOffset="-100px"
          topOffset="100px"
        />
      )}
    </div>
  );
};
