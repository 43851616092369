import styled from 'styled-components';

export const SummaryFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-size: 2.6rem;
  font-weight: 700;
`;
export const SummaryRespondentCounter = styled.span`
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.green[500]};
`;
