import * as React from 'react';
import { TextField } from 'Molecules';
import { GroupNameProps } from '../../types';
import { Wrapper } from './GroupName.styles';

export const GroupName: React.FC<GroupNameProps> = ({
  groupName,
  setGroupName,
}: GroupNameProps) => {
  return (
    <Wrapper data-testid="GroupName-Wrapper">
      <TextField
        value={groupName}
        showLabel={false}
        label={'GroupName'}
        placeholder="Group name..."
        handleChange={(e) => setGroupName(e.target.value as string)}
      />
    </Wrapper>
  );
};
