import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapValuesToOptions } from 'Pages/dashboard/contributions/utils';
import CustomSelect from '../CustomSelect/CustomSelect';
import { GroupContainerWrapper } from './styles';

interface GroupContainerProps {
  rowIndex: number;
  rowGroup: string;
  openToTop: boolean;
  updateRowGroup: (rowIndex: number, newGroup: string) => void;
}

const groupOptions = mapValuesToOptions(['AND', 'OR']);

export default function GroupContainer({
  rowIndex,
  rowGroup,
  openToTop,
  updateRowGroup,
}: GroupContainerProps) {
  const { t } = useTranslation('customer');
  return (
    <GroupContainerWrapper data-gi="globalFiltersV2-groups-container">
      {!rowIndex ? (
        <p>{t('Where')}</p>
      ) : (
        <CustomSelect
          options={groupOptions}
          openToTop={openToTop}
          value={mapValuesToOptions([rowGroup])}
          onChange={({ value }) => updateRowGroup(rowIndex, value)}
        />
      )}
    </GroupContainerWrapper>
  );
}
