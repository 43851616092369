import styled from 'styled-components';
import { SimpleSelect } from 'Molecules/SimpleSelect';

export const Select = styled(SimpleSelect)`
  width: 100%;
  .react-select__control {
    border: none;
    width: 100%;
    flex-wrap: nowrap;
    &.react-select__control--is-focused {
      border: none;
    }
  }
  .react-select__value-container {
    padding: 0 !important;
    min-width: 2.5rem;
    flex: 1 3;
  }
  .react-select__single-value {
    span {
      font-weight: 600;
      font-size: 1rem;
    }
  }
  .react-select__indicators {
    flex: 0;
    margin-right: 0.5rem;
  }
  .react-select__indicator {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.colors.grey[400] : theme.colors.black[300]};
    padding: 0;
    :hover {
      color: ${({ theme }) => theme.colors.black[300]};
    }
  }
  .react-select__menu {
    width: 100%;
    left: -1px;
    margin-bottom: 0;
    .react-select__option {
      line-height: 1rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
  }
  .react-select__menu-list {
    max-height: 10rem;
    padding: 0;
  }
`;
