import * as React from 'react';
import { NoFilterRowIcon } from './EmptyFilterIcon';
import { Wrapper, Label } from './EmptyFilter.styles';

export default function EmptyFilter() {
  return (
    <Wrapper data-gi="globalFiltersV2-empty-filter">
      <NoFilterRowIcon />
      <Label>+ Add filter to start your search</Label>
    </Wrapper>
  );
}
