import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  bottom: 105%;
  right: 5%;
  z-index: 100;
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0.15rem;
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.grey['A200']};
    background: ${theme.colors.white[500]};
  `}
`;
