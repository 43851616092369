import styled from 'styled-components';

export const Content = styled.div`
  padding: 1.875rem 2.75rem;
`;

export const ErrorMessageContainer = styled.div`
  width: 75%;
  min-width: 35rem !important;
`;
