import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 10rem;
  margin-bottom: 0.5rem;
  ${({ theme }) => css`
    .react-select__control {
      background-color: transparent;
      border: none;
      border-bottom: 0.1rem solid;
      border-radius: 0;
      &:hover {
        border-color: black;
      }
      &.react-select__control--is-focused {
        border-bottom: 0.1rem solid;
        box-shadow: none;
      }
    }
    .react-select__value-container {
      font-weight: ${theme.fontWeights.bold};
      justify-content: flex-start;
    }
    .react-select__single-value {
      font-size: 1rem;
      color: ${theme.colors.black[500]};
    }
    .react-select__placeholder {
      font-size: ${theme.texts.plainText.fontSize};
    }
    .react-select__indicator-separator {
      display: none;
    }
    .react-select__indicator {
      padding: 0;
      svg {
        color: #2d2926;
      }
    }
    .react-select__menu {
      z-index: 2;
      right: 0;
      width: 21.8rem;
    }
    .react-select__menu-list {
      padding-bottom: 0;
      max-height: 22rem;
    }
    .react-select__option {
      font-weight: ${theme.fontWeights.semi};
      display: flex;
      align-items: center;
      &.react-select__option--is-focused {
        background-color: ${theme.colors.grey[300]};
      }
      &.react-select__option--is-selected {
        background-color: ${theme.colors.blue[50]};
        color: ${theme.colors.green[500]};
      }
    }
    .react-select__group {
      border-top: 1px solid rgba(151, 151, 151, 0.3);
      :first-child {
        border-top: none;
      }
    }
    .react-select__group-heading {
      padding: 0;
      margin: 0;
    }
  `}
`;
