import { FilterOption, FilterRowMetadata } from 'Shared/types/filters';
import { OptionItem } from 'Client/types';
import { mapValuesToOptions } from 'Pages/dashboard/contributions/utils';

// requires metadata
const specialQuestionTypes = ['priorities', 'budget', 'matrix'];

export default function mapRowMetadata(
  filterOption: FilterOption
): FilterRowMetadata {
  const { questionContent } = filterOption;
  if (
    !questionContent ||
    !specialQuestionTypes.includes(questionContent.type)
  ) {
    return {};
  }

  const metadata: FilterRowMetadata = {};

  if (questionContent.type === 'budget') {
    const budgetOptions = questionContent.options as OptionItem[];
    metadata.budget = { budgetOptions };
    return metadata;
  }
  if (questionContent.type === 'matrix') {
    const { columns, topics } = questionContent.matrixOptions as {
      columns: OptionItem[];
      topics: OptionItem[];
    };
    metadata.matrix = { columns, topics };
    return metadata;
  }
  if (questionContent.type === 'priorities') {
    const priorityMax = (questionContent.values as unknown[]).length;
    const priorityOptions = mapValuesToOptions(
      questionContent.values as string[]
    );
    const priorityRanks = mapValuesToOptions(
      new Array(priorityMax).fill(0).map((_, index) => index + 1)
    );
    metadata.priority = { priorityMax, priorityRanks, priorityOptions };
    return metadata;
  }

  return metadata;
}
