import * as React from 'react';
import { ChevronDownIcon } from 'Client/components/atoms/Icons';
import { mapSortObject, isExpanded } from '../../utils';
import { DataHeaderProps } from '../../types';
import { formatQuestionsColumnsId } from '../../utils/formatQuestionsColumnsId';
import {
  HeaderTitle,
  HeaderTitleWrapper,
  HeaderSort,
  TableHeader,
  TableResizer,
} from './DataHeader.styles';

export const DataHeader: React.FC<DataHeaderProps> = (
  props: DataHeaderProps
) => {
  const {
    column,
    tableSort,
    setTableSort,
    selectedColumnId,
    setSelectedColumnId,
    children,
  } = props;
  const isModalDisplayable = React.useRef(true);
  const headerDropDownDelayInMS = 500;

  const isChildColumn = Boolean(column.parent);
  const isValidChildColumn = column.parent && column.id !== 'row-selection';
  const isColumnSelected = Boolean(
    isValidChildColumn && selectedColumnId === column.id
  );
  const isColumnResizable = column.canResize;
  const [currentColumnId, setcurrentColumnId] = React.useState('');

  if (!column.Header) {
    return <></>;
  }

  const onHoverColumn = (columnId: string) => {
    setSelectedColumnId(columnId);
  };

  const onSort = () => {
    const mappedSortObject = mapSortObject({
      currentSort: tableSort,
      newColumnId: column.id,
    });
    setTableSort(mappedSortObject);
  };

  React.useEffect(() => {
    if (tableSort?.columnId) {
      setcurrentColumnId(tableSort.columnId);
    }
  }, [tableSort]);

  const renderSort = () => {
    const idsToRemove = {
      'commonplace-map-columns': true,
      'commonplace-utm-columns': true,
      consents: true,
    };

    if (
      isValidChildColumn &&
      !(idsToRemove[column?.parent?.id] || idsToRemove[column?.id])
    ) {
      return (
        <HeaderSort
          onClick={onSort}
          data-onboarding="dashboard-sort-table"
          data-testid="DataHeader-HeaderSort"
        >
          <ChevronDownIcon
            opacity="0.8"
            isExpanded={!isExpanded({ columnId: column.id, tableSort })}
            isSorted={
              !!tableSort &&
              !!tableSort.columnId &&
              formatQuestionsColumnsId(column.id) === currentColumnId
            }
          />
        </HeaderSort>
      );
    }
  };

  return (
    <TableHeader
      {...column.getHeaderProps()}
      className="th"
      data-testid="DataHeader"
      isChildColumn={isChildColumn}
      onMouseEnter={() => {
        isModalDisplayable.current = true;
        setTimeout(() => {
          if (isModalDisplayable.current) {
            onHoverColumn(column.id);
          }
        }, headerDropDownDelayInMS);
      }}
      onMouseLeave={() => {
        if (selectedColumnId === column.id) return;
        isModalDisplayable.current = false;
        onHoverColumn(null);
      }}
    >
      <HeaderTitleWrapper>
        <HeaderTitle
          isSelected={isColumnSelected}
          data-testid="DataHeader-HeaderTitle"
          isChildColumn={isChildColumn}
          data-onboarding={`DataHeader-${column.id}`}
          isSorted={
            !!tableSort &&
            !!tableSort.columnId &&
            formatQuestionsColumnsId(column.id) === currentColumnId
          }
        >
          {column.render('Header')}
        </HeaderTitle>
        {renderSort()}
      </HeaderTitleWrapper>
      {isColumnResizable && (
        <TableResizer
          {...column.getResizerProps()}
          data-testid="DataHeader-TableResizer"
        />
      )}
      {isColumnSelected && children}
    </TableHeader>
  );
};
