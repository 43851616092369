import { formatQuestionsColumnsId } from './formatQuestionsColumnsId';
import { IsExpanded } from '../types';

export const isExpanded = ({ columnId, tableSort }: IsExpanded): boolean => {
  if (!tableSort) return false;
  const formattedColumnId = formatQuestionsColumnsId(columnId);
  if (
    tableSort.columnId === formattedColumnId &&
    tableSort.sortDirection === 'asc'
  )
    return true;
  return false;
};
