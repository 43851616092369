import { NextRouter } from 'next/router';
import { FilterRow, FilterOption } from 'Shared/types/filters';
import { GlobalFiltersProps } from './types';
import getDefaultFilterRow from './utils/getDefaultFilterRow';
import updateProperty from './utils/updateProperty';
import updateCondition from './utils/updateCondition';
import mapFiltersToUrl from './utils/mapFiltersToUrl';

interface GetGlobalFiltersInternalsProps {
  filtersRow: FilterRow[];
  setFiltersRow: (newFiltersRow: FilterRow[]) => void;
  filterOptions: FilterOption[];
  additionalFilterable: GlobalFiltersProps['additionalFilterable'];
  allAnswers: GlobalFiltersProps['allAnswers'];
  router: NextRouter;
  onModalClose: () => void;
}

/**
 * This function contains the internal helper functions used by the GlobalFilters component.
 */
export default function getGlobalFiltersInternals({
  filtersRow,
  setFiltersRow,
  filterOptions,
  additionalFilterable,
  allAnswers,
  router,
  onModalClose,
}: GetGlobalFiltersInternalsProps) {
  return {
    /**
     * Adds a new filter row to the filtersRow array.
     */
    addNewFilterRow: () => {
      const baseFilterRow = getDefaultFilterRow({
        filterOptions,
        additionalFilterable,
        allAnswers,
      });
      if (!baseFilterRow) return;
      const newFilterItem: FilterRow = filtersRow.length
        ? { ...baseFilterRow, group: 'AND' }
        : baseFilterRow;
      setFiltersRow([...filtersRow, newFilterItem]);
    },
    /**
     * Updates the group of the specified filter row.
     */
    updateGroupForFilterRow: (rowIndex: number, newGroup: 'AND' | 'OR') => {
      const newFiltersRow = [...filtersRow];
      newFiltersRow[rowIndex].group = newGroup;
      setFiltersRow(newFiltersRow);
    },
    /**
     * Updates the property of the specified filter row.
     */
    updatePropertyForFilterRow: (rowIndex: number, newProperty: string) => {
      const currentRow = filtersRow[rowIndex];
      const updatedRow = updateProperty({
        currentRow,
        newProperty,
        filterOptions,
        additionalFilterable,
        allAnswers,
      });
      const newFiltersRow = [...filtersRow];
      newFiltersRow[rowIndex] = updatedRow;
      setFiltersRow(newFiltersRow);
    },
    /**
     * Updates the condition of the specified filter row.
     */
    updateConditionForFilterRow: (rowIndex: number, newCondition: string) => {
      const currentRow = filtersRow[rowIndex];
      const updatedRow = updateCondition({
        currentRow,
        filterOptions,
        newCondition,
      });
      const newFiltersRow = [...filtersRow];
      newFiltersRow[rowIndex] = updatedRow;
      setFiltersRow(newFiltersRow);
    },
    /**
     * Updates the value of the specified filter row.
     */
    updateValueForFilterRow: (rowIndex: number, newValue: unknown) => {
      const newFiltersRow = [...filtersRow];
      newFiltersRow[rowIndex].value = newValue;
      setFiltersRow(newFiltersRow);
    },
    /**
     * Updates the metadata of the specified filter row.
     */
    updateMetadataForFilterRow: (
      rowIndex: number,
      keyToUpdate: string,
      newValue: unknown
    ) => {
      // e.g ['metadata', 'budget', 'budgetValue']
      const [metadata, field, property] = keyToUpdate.split('.');
      const newFiltersRow = [...filtersRow];
      newFiltersRow[rowIndex][metadata][field][property] = newValue;
      setFiltersRow(newFiltersRow);
    },
    /**
     * Deletes the specified filter row.
     */
    deleteFilterRow: (rowIndex: number) => {
      const newRows = filtersRow.filter((_, index) => index !== rowIndex);
      setFiltersRow(newRows);
    },
    /**
     * Add current filter rows to the url
     */
    applyFilters: () => {
      const { query, basePath } = router;
      const mappedFilters = mapFiltersToUrl(filtersRow, filterOptions);
      const stringFilters = JSON.stringify(mappedFilters);
      router.push({
        pathname: basePath,
        query: { ...query, nestedFilters: stringFilters },
      });
      onModalClose();
    },
    /**
     * Remove nested filters from the URL
     */
    clearFilters: () => {
      const { query, basePath } = router;
      const newQuery = query;
      delete newQuery.nestedFilters;
      router.push({
        pathname: basePath,
        query: newQuery,
      });
      onModalClose();
    },
  };
}
