import styled from 'styled-components';

export const Wrapper = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3.75rem;
  box-shadow: 0 0 1.5rem rgb(0 0 0 / 10%);
  padding: 1.25rem 5.5rem;
  background: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
`;
export const LeftSideWrapper = styled.div`
  p {
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.fontWeights.semi};
  }
`;
export const RightSideWrapper = styled.div``;
