import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 10;
  position: absolute;
  right: 1rem;
  top: 3rem;
  min-width: 12.5rem;
  max-width: 25rem;
  border-radius: 0.3rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 1.5rem rgb(0 0 0 / 10%);
  background: ${({ theme }) => theme.colors.white[500]};
`;
