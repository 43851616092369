import styled from 'styled-components';

const footerParagraph = styled.span`
  margin: 0;
  font-size: 0.75rem;
`;
export const SummaryHeaderDivider = styled.span`
  color: ${({ theme }) => theme.colors.grey[500]};
`;
export const SummaryFooterRow = styled.div`
  display: flex;
  align-items: center;
  height: 1rem;
  margin-left: 0.2rem;
`;
export const SummaryFooterItemWrapper = styled.div`
  margin-right: 1rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.35rem;
  }
  :first-child {
    margin-left: 0;
  }
`;
export const AgreementIconWrapper = styled.span`
  svg {
    transform: scale(1.2);
    margin-top: 0.2rem;
  }
`;
export const SummaryFooterInfo = styled(footerParagraph)`
  font-weight: 900;
`;
export const SummaryFooterLabel = styled(footerParagraph)`
  padding-left: 0.2rem;
`;
