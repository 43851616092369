import { FilterRow } from 'Shared/types/filters';
import { OptionItem } from 'Client/types';

interface HelperContainerData {
  updateKey: string;
  placeholder: string;
  options: OptionItem[];
  metadataValue: string | number | string[];
  isMulti: boolean;
}

export const mapHelperContainerData = (row: FilterRow): HelperContainerData => {
  if (row.metadata.priority) {
    const { priorityValue, priorityOptions } = row.metadata.priority;
    const updateKey = 'metadata.priority.priorityValue';
    const placeholder = 'Choose a priority';
    return {
      updateKey,
      placeholder,
      options: priorityOptions,
      metadataValue: priorityValue,
      isMulti: true,
    };
  }
  if (row.metadata.budget) {
    const { budgetOptions, budgetValue } = row.metadata.budget;
    const updateKey = 'metadata.budget.budgetValue';
    const placeholder = 'Select value allocated';
    return {
      options: budgetOptions,
      metadataValue: budgetValue,
      updateKey,
      placeholder,
      isMulti: false,
    };
  }
  if (row.metadata.matrix) {
    const { topics, topicValue } = row.metadata.matrix;
    const updateKey = 'metadata.matrix.topicValue';
    const placeholder = 'Select topic';
    return {
      options: topics,
      metadataValue: topicValue,
      updateKey,
      placeholder,
      isMulti: false,
    };
  }
  return null;
};
