import styled, { css } from 'styled-components';

export const GroupContainerWrapper = styled.div`
  p,
  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.grey[900]};
  }
  display: flex;
  flex: 2 1;
  .react-select-container {
    width: 100%;
  }
`;

export const PropertiesContainerWrapper = styled.div`
  flex: 10;
  .react-select__value-container {
    flex: 9;
  }
  .react-select__indicators {
    flex: 1;
  }
  .react-select__menu {
    width: 100%;
  }
`;

export const ConditionContainerWrapper = styled.div`
  flex: 4;
`;

export const ValueContainerWrapper = styled.div<{ isDisabled: boolean }>`
  flex: 8;
  border: 1px solid #666666;
  border-radius: 0.1rem;
  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      border-color: ${theme.colors.grey[400]};
    `}
  .react-select__value-container {
    margin-left: 1rem;
  }
  .react-select__indicators {
    margin-right: 1rem;
  }
  .MuiFormControl-root {
    min-width: 0 !important;
    width: 100%;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
