import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }) =>
    theme.colorMappings.contributionsTableHeaderModalNoOptionsBackground};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const IconContainer = styled.div`
  margin: 0;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
`;
