export const formatQuestionsColumnsId = (columnId: string) => {
  if (columnId.includes('questionData.')) {
    return columnId.split('questionData.')[1];
  }

  if (!columnId.includes(':')) return columnId;

  const splittedColumnId = columnId.split(':');

  const questionId = splittedColumnId[1];

  return `answers.${questionId}`;
};
