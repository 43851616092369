import styled from 'styled-components';

export const ListItem = styled.li`
  margin: 0 1rem 1rem;
  display: flex;
  align-items: center;
  span {
    font-size: 0.875rem;
    font-weight: 400;
    flex: 1;
  }
`;
