import * as React from 'react';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { useProject } from 'Client/utils/hooks';
import { DateDisplayProps } from 'Pages/dashboard/components/DateFilter/types';
import { WHOLE_PROJECT } from 'Pages/dashboard/components/DateFilter/predefinedOptions';
import { Wrapper } from './DateDisplay.styles';

export const DateDisplay: React.FC<DateDisplayProps> = ({
  dateRange,
  selectedOption,
  lastDate,
  isLoading,
}: DateDisplayProps) => {
  const {
    stage,
    launchDate: projectLaunchDate,
    endDate: projectEndDate,
  } = useProject();
  const launchDate = new Date(projectLaunchDate);
  const endDate = new Date(projectEndDate);
  const formatPattern = 'dd/MM/yyyy';

  if (selectedOption === WHOLE_PROJECT) {
    const wholeProjectFormat = (finalDate = new Date()) => {
      return `${format(launchDate, formatPattern)} -  ${format(
        finalDate,
        formatPattern
      )}`;
    };

    if (!isValid(launchDate)) return <Wrapper />;
    if (stage === 'active') {
      const formattedDate = wholeProjectFormat();
      return <Wrapper>{formattedDate}</Wrapper>;
    }
    if (stage === 'closed' || stage === 'completed') {
      if (isValid(endDate)) {
        const formattedDate = wholeProjectFormat(endDate);
        return <Wrapper>{formattedDate}</Wrapper>;
      }
      if (!isLoading) {
        const formattedDate = wholeProjectFormat(lastDate || new Date());
        return <Wrapper>{formattedDate}</Wrapper>;
      }
      <Wrapper>loading...</Wrapper>;
    }
  }
  if (!dateRange || !dateRange.from || !dateRange.to) return <Wrapper />;
  const from = dateRange.from as Date;
  const to = dateRange.to as Date;
  const formattedDate = `${format(from, formatPattern)} - ${format(
    to,
    formatPattern
  )}`;
  return <Wrapper>{formattedDate}</Wrapper>;
};
