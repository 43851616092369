import * as React from 'react';
import { SvgRenderer } from 'Molecules/SvgRenderer';

export const NoFilterRowIcon: React.FC = () => {
  return (
    <SvgRenderer data-testid="NoFilterRowIcon-SvgRenderer">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357 2C357 0.895431 357.895 0 359 0H655C656.105 0 657 0.895431 657 2V38C657 39.1046 656.105 40 655 40H359C357.895 40 357 39.1046 357 38V2Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238 2C238 0.895431 238.895 0 240 0H323C324.105 0 325 0.895431 325 2V38C325 39.1046 324.105 40 323 40H240C238.895 40 238 39.1046 238 38V2Z"
        fill="url(#paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119 2C119 0.895431 119.895 0 121 0H204C205.105 0 206 0.895431 206 2V38C206 39.1046 205.105 40 204 40H121C119.895 40 119 39.1046 119 38V2Z"
        fill="url(#paint2_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.89543 0 2 0H85C86.1046 0 87 0.895431 87 2V38C87 39.1046 86.1046 40 85 40H2C0.89543 40 0 39.1046 0 38V2Z"
        fill="url(#paint3_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="256"
          y1="-9.50003"
          x2="769"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEEEE" />
          <stop offset="1" stopColor="#BFBFBF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="238"
          y1="0"
          x2="341.5"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBEBEB" />
          <stop offset="1" stopColor="#B7B7B7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="206"
          y1="6"
          x2="103"
          y2="27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEEEE" />
          <stop offset="1" stopColor="#B7B7B7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="-6.5"
          y1="20"
          x2="87"
          y2="25.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFEFEF" />
          <stop offset="1" stopColor="#ADADAD" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgRenderer>
  );
};
