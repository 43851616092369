import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0.4rem 0;
  gap: 1rem;
`;

export const DeleteRowButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey[900]};
  flex: 0 3;
  padding-top: 0.2rem;
  svg {
    stroke-width: 2;
    width: 0.65rem;
    height: 0.65rem;
    margin-right: 0.5rem;
  }
`;
