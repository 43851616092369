import * as React from 'react';
import { SummaryPageProps } from './types';
import { FooterRow, Title } from './components';
import { mapFooterData } from './utils';

const Summary: React.FC<SummaryPageProps> = ({
  title,
  commentsCount,
  agreementsCount,
  contributionsCount,
}: SummaryPageProps) => {
  return (
    <div>
      <Title title={title} counterTotal={contributionsCount} />
      <FooterRow
        footerData={mapFooterData({
          totalComments: commentsCount,
          totalAgreements: agreementsCount,
        })}
      />
    </div>
  );
};

export { Summary };
