import * as React from 'react';
import { DropDownItemProps, ActionBarProps } from './types';
import { ButtonItem, DropDown, DropDownItem } from './components';
import { LeftSideWrapper, RightSideWrapper, Wrapper } from './ActionBar.styles';

const renderDropDownItems = (group: DropDownItemProps) => {
  return (
    <DropDownItem
      key={group.label}
      label={group.label}
      dataOnboarding={group.dataOnboarding}
      action={group.action}
    />
  );
};

export const ActionBar: React.FC<ActionBarProps> = ({
  totalSelected,
  buttons,
  children,
}: ActionBarProps) => {
  const [openDropDown, setOpenDropDown] = React.useState<string>(null);

  /* const moreActionItems = [
    { label: 'Send newsletter', action: () => {} },
    { label: 'Mark as suspicious', action: () => {} },
    { label: 'Mark as abusive', action: () => {} },
  ]; */

  const handleButtonsOnClick = (button) => {
    if (button.dropdown) {
      if (openDropDown === button.label) {
        setOpenDropDown(null);
        return;
      }
      setOpenDropDown(button.label);
      return;
    }
    button.onClick();
  };

  return (
    <Wrapper>
      <LeftSideWrapper>
        <p data-testid="Selected-Rows-P">{`${totalSelected} selected`}</p>
      </LeftSideWrapper>
      <RightSideWrapper>
        {children}
        {buttons.map((button) => {
          return (
            <ButtonItem
              key={button.label}
              testid={`${button.label}-Button`}
              buttonLabel={button.label}
              openDropDown={openDropDown}
              onClick={() => handleButtonsOnClick(button)}
            >
              {button.dropdown && (
                <DropDown>
                  {button.dropdownItems.map(renderDropDownItems)}{' '}
                </DropDown>
              )}
            </ButtonItem>
          );
        })}
      </RightSideWrapper>
    </Wrapper>
  );
};
