import React from 'react';
import { TextField as MuiTextField } from 'Molecules/TextField';

interface CustomNumericFieldProps {
  value: number;
  updateValue: (newValue: unknown) => void;
}

export default function CustomNumericField({
  value,
  updateValue,
}: CustomNumericFieldProps) {
  return (
    <MuiTextField
      value={value}
      showLabel={false}
      placeholder="Type a number to filter by"
      label=""
      type="number"
      handleChange={(event) => {
        const { value } = event.target;
        updateValue(Number(value));
      }}
      inputProps={{
        min: 0,
        step: 1,
      }}
    />
  );
}
