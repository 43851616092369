import * as React from 'react';
import { theme } from 'Theme';
import { OptionItem } from 'Client/types';
import { Select } from './CustomSelect.styles';
import { getOptionColor } from './helpers';

interface CustomSelectProps {
  options: OptionItem[];
  onChange: (value) => void;
  value: OptionItem | OptionItem[];
  openToTop: boolean;
  defaultValue?: OptionItem;
  isMulti?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
}

export default function CustomSelect({
  options,
  defaultValue,
  onChange,
  value,
  isMulti,
  isDisabled = false,
  openToTop,
  placeholder = '',
}: CustomSelectProps) {
  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      placeholder={isDisabled ? '-----' : placeholder}
      noFocusHighlight={true}
      isClearable={false}
      handleChange={onChange}
      value={isDisabled ? null : value}
      menuShouldScrollIntoView={false}
      isMulti={isMulti}
      isDisabled={isDisabled}
      menuPlacement={openToTop ? 'top' : 'bottom'}
      styles={{
        multiValue: (base, state) => ({
          ...base,
          borderRadius: '1rem',
          fontSize: '1rem',
          color: theme.colors.white[500],
          fontWeight: 600,
          backgroundColor: getOptionColor(String(state.data.value)),
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: theme.colors.white[500],
          paddingTop: 0,
          paddingBottom: 0,
        }),
      }}
    />
  );
}
