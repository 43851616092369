import React from 'react';
import { CancelIcon } from 'Icons';
import { PageType } from 'Pages/dashboard/components/GlobalFiltersV2/types';
import {
  DialogHeader,
  HeaderTitle,
  HeaderOptions,
  GreenButtonWithCancelIcon,
  HeaderCloseButton,
} from './ModalHeader.styles';

interface ModalHeaderProps {
  pageType: PageType;
  onModalClose: () => void;
  resetFilters: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  pageType,
  onModalClose,
  resetFilters,
}: ModalHeaderProps) => {
  return (
    <DialogHeader>
      <HeaderTitle>{pageType}</HeaderTitle>
      <HeaderOptions>
        <GreenButtonWithCancelIcon
          onClick={resetFilters}
          data-gi="globalFiltersV2-reset-filters-btn"
        >
          <CancelIcon />
          <span>Reset filters</span>
        </GreenButtonWithCancelIcon>
        <HeaderCloseButton
          onClick={onModalClose}
          data-gi="globalFiltersV2-close-modal-btn"
        >
          <CancelIcon />
        </HeaderCloseButton>
      </HeaderOptions>
    </DialogHeader>
  );
};

export default ModalHeader;
