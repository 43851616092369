import { GetNewSentimentsProps } from '../types';

export const getNewSentiments = ({
  selectedSentiments = [],
  sentiment,
}: GetNewSentimentsProps) => {
  const isAlreadySelected = selectedSentiments.find(
    (selectedSentiment) => selectedSentiment === sentiment.id
  );
  if (isAlreadySelected) {
    const newSelectedSentiments = selectedSentiments.filter(
      (selectedSentiment) => selectedSentiment !== sentiment.id
    );
    return newSelectedSentiments;
  }
  const newSelectedSentiments = [...selectedSentiments, sentiment.id];
  return newSelectedSentiments;
};
