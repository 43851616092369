import styled, { css } from 'styled-components';

export const BaseSwitch = styled.label`
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  position: relative;
`;

export const IconsWrapper = styled.span<{ checked: boolean }>`
  ${({ theme, checked }) => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${theme.colors.grey['A200']};
    border-radius: 2.5rem;
    cursor: pointer;
    ${checked &&
    css`
      background: ${theme.colors.blue[50]};
    `}
    svg {
      width: 0.875rem;
      height: 0.75rem;
      color: ${theme.colors.white[500]};
      transform: scale(1.4);
    }
    &:before {
      content: '';
      height: 1.25rem;
      width: 1.25rem;
      position: absolute;
      left: 0.0625rem;
      bottom: 0.125rem;
      border-radius: 50%;
      background-color: ${theme.colors.grey['A700']};
      transition: 0.2s all ease;
      ${checked &&
      css`
        transform: translateX(120%);
        background-color: ${theme.colors.green['A700']};
      `}
    }
    svg:nth-child(${checked ? 1 : 2}) {
      visibility: hidden;
    }
  `}
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;
