import * as React from 'react';
import { format, subMonths, addMonths, endOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon, ChevronLeftIcon } from 'Atoms/Icons';
import { theme } from 'Client/components/theme';
import {
  Calendar,
  SelectedDates,
  Week,
  Day,
  Month,
  Wrapper,
} from './DateRangePicker.styles';
import {
  getMonthDataOfDate,
  isBetweenDates,
  isSelectedDate,
  isToDate,
} from './utils';
import { months } from './const';
import { DateRangePickerProps } from './types';

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  dateTo,
  dateFrom,
  currentMonth,
  setDateFrom,
  setDateTo,
  setCurrentMonth,
  setIsCalendarHovered,
  isDashboard,
}) => {
  console.log('first log of range picker', dateTo, dateFrom);
  const { t } = useTranslation();
  const dateToCleanUp = dateTo || new Date();
  const dateFromCleanUp = dateFrom || new Date();

  const [calendarMonth, setCalendarMonth] = React.useState(
    getMonthDataOfDate(currentMonth)
  );
  const [lastSelectedDate, setLastSelectedDate] = React.useState<'from' | 'to'>(
    'to'
  );

  const handleGoToPreviousMonth = () => {
    const previousMonth = subMonths(currentMonth, 1);

    setCurrentMonth(previousMonth);
    setCalendarMonth(getMonthDataOfDate(previousMonth));
  };

  const handleGoToNextMonth = () => {
    const nextMonth = addMonths(currentMonth, 1);

    setCurrentMonth(nextMonth);
    setCalendarMonth(getMonthDataOfDate(nextMonth));
  };

  const handleSelectDate = (day: number) => {
    if (!day) return;
    const date = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day
    );
    const dateToEndOfDay = endOfDay(date); // set hour to 23:59 to include end date data

    console.log(date, 'date picker');
    if (lastSelectedDate === 'to') {
      if (day === dateToCleanUp.getDate()) {
        setDateTo(dateToEndOfDay);
      }
      date > dateToCleanUp ? setDateTo(dateToEndOfDay) : setDateFrom(date);
      return date > dateToCleanUp
        ? setLastSelectedDate('to')
        : setLastSelectedDate('from');
    }

    date < dateFromCleanUp ? setDateFrom(date) : setDateTo(dateToEndOfDay);
    date < dateFromCleanUp
      ? setLastSelectedDate('from')
      : setLastSelectedDate('to');

    if (day === dateFromCleanUp.getDate()) {
      setDateTo(dateToEndOfDay);
    }
  };

  React.useEffect(() => {
    setCalendarMonth(getMonthDataOfDate(currentMonth));
  }, [currentMonth]);

  const renderDays = () => {
    const columns = Math.ceil(
      (calendarMonth.daysInMonth + calendarMonth.weekFirstDay) / 7
    );

    const days = Array.from({ length: columns }, () => {
      return { weekDays: [] };
    });

    const formatMonthDays = (day = 1, weekNumber = 0) => {
      if (weekNumber + 1 > columns) return;

      if (
        weekNumber === 0 &&
        days[weekNumber].weekDays.length < calendarMonth.weekFirstDay
      ) {
        days[weekNumber].weekDays.push('');
        return formatMonthDays();
      }

      const dayOfWeek = days[weekNumber].weekDays?.length;
      const dayToRender = day > calendarMonth.daysInMonth ? '' : day;

      days[weekNumber].weekDays.push(dayToRender);

      formatMonthDays(day + 1, dayOfWeek < 6 ? weekNumber : weekNumber + 1);
    };

    formatMonthDays();

    return (
      <div>
        {days.map((week) => (
          <Week key={`${week.weekDays[0]}`}>
            {week.weekDays.map((day, index) => (
              <Day
                tabIndex={7}
                key={`${day + index}`}
                onClick={() => handleSelectDate(day)}
                onKeyDown={(e) => {
                  if (e.code === 'Space') {
                    e.preventDefault();
                    handleSelectDate(day);
                  }
                }}
                isSelected={isSelectedDate({
                  day,
                  dateFrom: dateFromCleanUp,
                  dateTo: dateToCleanUp,
                  calendarMonth,
                })}
                isBetween={isBetweenDates({
                  day,
                  dateFrom: dateFromCleanUp,
                  dateTo: dateToCleanUp,
                  calendarMonth,
                })}
                isToDate={isToDate({ day, dateTo, calendarMonth })}
              >
                {day}
              </Day>
            ))}
          </Week>
        ))}
      </div>
    );
  };

  return (
    <Wrapper
      isDashboard={isDashboard}
      onMouseEnter={() => setIsCalendarHovered(true)}
      onMouseLeave={() => setIsCalendarHovered(false)}
    >
      <SelectedDates>
        <span>{t('From:')}</span>
        {format(dateFromCleanUp, 'dd/MM/yyyy')}
        <span>{t('To:')}</span>
        {format(dateToCleanUp, 'dd/MM/yyyy')}
      </SelectedDates>
      <Calendar>
        <Month>
          <ChevronLeftIcon
            tabIndex={5}
            color={theme.colors.green[500]}
            onClick={handleGoToPreviousMonth}
            onKeyDown={(e) => {
              if (e.code === 'Space') {
                e.preventDefault();
                handleGoToPreviousMonth();
              }
            }}
          />
          {`${months[calendarMonth.monthNumber]} ${calendarMonth.year}`}
          <ChevronRightIcon
            tabIndex={6}
            color={theme.colors.green[500]}
            onClick={handleGoToNextMonth}
            onKeyDown={(e) => {
              if (e.code === 'Space') {
                e.preventDefault();
                handleGoToNextMonth();
              }
            }}
          />
        </Month>
        {renderDays()}
      </Calendar>
    </Wrapper>
  );
};
