import styled from 'styled-components';

export const Wrapper = styled.div<{ type: string; isHighlighted: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 3rem;
  padding: 0.5rem;
  border-bottom: ${({ theme }) => `thin solid ${theme.colors.grey[200]}`};
  border-left: ${({ theme }) => `thin solid ${theme.colors.grey[200]}`};
  font-weight: 600;
  background: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.colors.green[100] : '#fff'};

  :hover {
    color: ${({ theme, type }) =>
      type !== 'visitors' && theme.colors.green[500]};
    cursor: pointer;
  }
`;
