import { TopFilters } from './dashboard';

export enum GroupReference {
  CONTRIBUTION = 'contribution',
  RESPONDENT = 'respondent',
}
export enum GroupType {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
}
export enum FilterGroupCondition {
  ROOT = 'root',
  AND = 'and',
  OR = 'or',
}

export interface GroupFilters {
  topFilters?: TopFilters;
  nestedFilters?: Array<GroupNestedFilters>;
}
export interface GroupNestedFilters {
  filterId?: string;
  filterGroup: FilterGroupCondition;
  filterProperty: string;
  question?: {
    questionId: string;
    versionId?: string;
    questionContent?: Record<string, unknown>;
  };
  filterCondition: string;
  filterValue: unknown;
}
export type GroupInfo = {
  _id?: string;
  projectId: string;
  name: string;
  reference: GroupReference;
  createdAt?: Date;
  updatedAt?: Date;
  type: GroupType;
  documentsIds?: Array<string>;
  filters?: GroupFilters;
};
