import { HasAppliedFiltersProps } from '../types';

export const hasAppliedFilters = ({
  filters,
}: HasAppliedFiltersProps): boolean => {
  const { nestedFilters, topFilters } = filters;
  const { date, proposals, sentiment } = topFilters || {};
  const { from, to } = date || {};
  const hasTopFilters = from || to || proposals?.length || sentiment?.length;
  const hasNestedFilters = Boolean(nestedFilters?.length);
  if (hasTopFilters || hasNestedFilters) return true;
  return false;
};
