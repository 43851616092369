import styled from 'styled-components';

const sharedTextStyle = styled.span`
  font-size: 0.8rem;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const SentimentBox = styled.div<{ selected: boolean }>`
  max-height: 2rem;
  margin-right: 1rem;
  display: flex;
  border: ${(props) => `1px solid ${props.color}`};
  border-radius: 1rem;
  align-items: center;
  padding: 0.6rem 0.8rem;
  cursor: pointer;
  ${({ color, selected, theme }) => `
    border: 1px solid ${color};
    ${
      selected &&
      `
      background-color: ${color};
      color: ${theme.colors.white[500]};
    `
    }
  `}
`;

export const SentimentCircle = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: ${(props) => props.color};
`;

export const SentimentValue = styled(sharedTextStyle)`
  margin-right: 0.3rem;
  font-size: 0.8rem;
  font-weight: 700;
`;

export const SentimentLabel = styled(sharedTextStyle)`
  font-weight: 600;
`;
