// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
/*
  This component is highly integrated with react-table which does not have typing,
  so I disabled the rules to make sure ts play nice with it.
*/

import * as React from 'react';
import { Checkbox as StyledCheckbox } from './TableCheckbox.styles';

const areEqual = (prevProps: any, nextProps: any) => {
  return (
    prevProps.checked === nextProps.checked &&
    prevProps.indeterminate === nextProps.indeterminate
  );
};

export const TableCheckbox = React.memo(function Checkbox({
  onChange,
  onToggle,
  ...rest
}) {
  const defaultRef = React.useRef();
  const resolvedRef = rest.ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = rest.indeterminate;
  }, [rest.indeterminate]);

  return (
    <StyledCheckbox
      ref={resolvedRef}
      type="checkbox"
      {...rest}
      onChange={(e) => {
        if (onToggle) {
          onToggle();
        }
        onChange(e);
      }}
    />
  );
},
areEqual);
