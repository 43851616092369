import * as React from 'react';
import { Wrapper } from './WarningIcon.styles';
import { IconProps } from '../types';

export const WarningIcon: React.FC<IconProps> = ({
  width = 20,
  height = 20,
  fill = '#F4B840',
}) => {
  return (
    <Wrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8329 10.1389C10.8329 10.5222 10.5217 10.8333 10.1384 10.8333C9.75508 10.8333 9.44397 10.5222 9.44397 10.1389V7.36109C9.44397 6.97776 9.75508 6.66665 10.1384 6.66665C10.5217 6.66665 10.8329 6.97776 10.8329 7.36109V10.1389ZM10.1384 13.75C9.75509 13.75 9.44398 13.4389 9.44398 13.0555C9.44398 12.6722 9.75509 12.3611 10.1384 12.3611C10.5218 12.3611 10.8329 12.6722 10.8329 13.0555C10.8329 13.4389 10.5218 13.75 10.1384 13.75V13.75ZM17.4724 13.266L12.1447 4.43331C11.7287 3.74442 10.9787 3.33331 10.1384 3.33331C9.29814 3.33331 8.54814 3.74442 8.13217 4.43331L2.80439 13.266C2.40995 13.9208 2.39814 14.7083 2.77314 15.3722C3.17523 16.0854 3.95648 16.5278 4.81064 16.5278H15.4662C16.3204 16.5278 17.1016 16.0854 17.5037 15.3722C17.8787 14.7083 17.8669 13.9208 17.4724 13.266Z"
          fill={fill}
        />
      </svg>
    </Wrapper>
  );
};
