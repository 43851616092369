import * as React from 'react';
import { Wrapper, Label } from './ToolTip.styles';
import { ToolTipProps } from '../../types';

export const ToolTip: React.FC<ToolTipProps> = ({
  isDisabled,
  text,
  children,
}: ToolTipProps) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  if (isDisabled) return <>{children}</>;
  return (
    <Wrapper
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {children}
      {isHovering && <Label>{text}</Label>}
    </Wrapper>
  );
};
