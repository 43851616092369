import * as React from 'react';
import { useSticky } from 'react-table-sticky';
import {
  useTable,
  useRowSelect,
  useBlockLayout,
  useResizeColumns,
} from 'react-table';
import { captureException } from '@sentry/react';
import { useRouter } from 'next/router';
import { LoadRing } from 'Molecules';
import { generateTableColumns } from 'Pages/dashboard/utils';
import { TableProps, Column } from './types';
import { Wrapper, TableContainer, TableHeaders } from './Table.styles';
import {
  DataHeader,
  HeaderDropDown,
  TableCheckbox,
  TableCell,
} from './components';
import { checkAllAutoToggle } from './utils';

const Table: React.FC<TableProps> = ({
  baseTableHeaders,
  rowsData,
  loading,
  fetching,
  loadMoreData,
  tableSort,
  setTableSort,
  hiddenColumns,
  setHiddenColumns,
  setSelectedRowsIds,
  setSelectedRows,
  setSelectedRowsTableIds,
  selectedRowsTableIds,
  setFlatRowsIds,
  onAllRowsCheckboxToggle,
  isAllRowsCheckboxSelected,
  isWaypointInside,
  tableType,
}: TableProps) => {
  const router = useRouter();
  const [isFetching, setFetching] = React.useState(false);
  const [pinnedColumn, setPinnedColumn] = React.useState<Column>(null);
  const [selectedColumnId, setSelectedColumnId] = React.useState<string>(null);

  const { id, sliderTab, tab } = router.query;

  const CheckboxColumnHeader = ({ getToggleAllRowsSelectedProps }) => (
    <TableCheckbox
      {...getToggleAllRowsSelectedProps()}
      onToggle={onAllRowsCheckboxToggle}
    />
  );
  const CheckboxCell = ({ row }) => (
    <TableCheckbox {...row.getToggleRowSelectedProps()} />
  );

  const columns = React.useMemo(() => {
    const newHeaders = generateTableColumns({
      baseColumns: baseTableHeaders,
      pinnedColumn,
      CheckboxColumnHeader,
      CheckboxCell,
    });
    return newHeaders;
  }, [baseTableHeaders, pinnedColumn]);
  const data = React.useMemo(() => rowsData, [rowsData]);

  const prepareSelectedRows = (params: {
    selectedRows?: Array<string>;
    selectAllRows?: boolean;
  }) => {
    const idsObject = {};
    if (params.selectAllRows) {
      rowsData.map((_obj, index) => {
        idsObject[index] = true;
      });
      return idsObject;
    }

    params.selectedRows.map((id) => {
      idsObject[id] = true;
    });
    return idsObject;
  };

  const {
    selectedFlatRows,
    flatHeaders,
    setHiddenColumns: updateHiddenColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    flatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: isAllRowsCheckboxSelected
          ? prepareSelectedRows({ selectAllRows: true })
          : prepareSelectedRows({ selectedRows: selectedRowsTableIds }),
      },
    },
    useRowSelect,
    useResizeColumns,
    useBlockLayout,
    useSticky
  );

  const handleLoadMore = async (lastIndex, lastItem) => {
    try {
      setFetching(true);
      const lastId = lastItem.original._id;
      await loadMoreData(lastId, lastIndex);
    } catch (error) {
      captureException(`Error in loadMoreData @ Table : ${error}`);
    } finally {
      setFetching(false);
    }
  };

  const handlePinnedColumn = (column: Column) => {
    if (pinnedColumn && pinnedColumn.id === column.id) {
      setPinnedColumn(null);
      return;
    }
    setPinnedColumn(column);
  };

  const handleHideColumn = (column) => {
    const { Header, id } = column;
    const newHiddenColumns = [
      ...hiddenColumns,
      { columnId: id, columnHeader: Header },
    ];
    setHiddenColumns(newHiddenColumns);
  };

  React.useEffect(() => {
    if (isWaypointInside) {
      handleLoadMore(rows.length, rows[rows.length - 1]);
    }
  }, [isWaypointInside]);

  React.useEffect(() => {
    const columnsToUpdate = hiddenColumns.map(
      (hiddenColumn) => hiddenColumn.columnId
    );
    updateHiddenColumns(columnsToUpdate);
  }, [hiddenColumns]);

  React.useEffect(() => {
    checkAllAutoToggle(
      flatRows,
      selectedFlatRows,
      onAllRowsCheckboxToggle,
      isAllRowsCheckboxSelected
    );
  }, [flatRows, selectedFlatRows, isAllRowsCheckboxSelected]);

  React.useEffect(() => {
    const selectedRowsIds = selectedFlatRows.reduce(
      (acc, row) => {
        acc['tableIds'].push(row.id);
        acc['ids'].push(row.original._id);
        acc['original'].push(row.original);

        return acc;
      },
      {
        original: [],
        tableIds: [],
        ids: [],
      }
    );

    setSelectedRowsTableIds(selectedRowsIds.tableIds);
    setSelectedRowsIds && setSelectedRowsIds(selectedRowsIds.ids);
    setSelectedRows && setSelectedRows(selectedRowsIds.original);
  }, [selectedFlatRows]);

  React.useEffect(() => {
    const flatRowsIds = flatRows.map((row) => row.original._id);

    setFlatRowsIds(flatRowsIds);
  }, [flatRows]);

  if (loading) {
    return <LoadRing loadingMessage="Loading" />;
  }

  return (
    <>
      <Wrapper data-testid="Table">
        <TableContainer {...getTableProps()} className="table sticky">
          <TableHeaders className="header">
            {headerGroups.map((headerGroup, index) => (
              <div
                key={`trh:${index}`}
                className="tr"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  const isPinned =
                    pinnedColumn && pinnedColumn.id === column.id;
                  const handlePin = () => {
                    handlePinnedColumn(column);
                    setSelectedColumnId(null);
                  };

                  const lastColumnHeader =
                    flatHeaders[flatHeaders.length - 1].Header;

                  if (hiddenColumns.some((c) => c.columnId === column.id)) {
                    return;
                  }

                  return (
                    <DataHeader
                      key={`trth:${index}:${column.id}`}
                      column={column}
                      tableSort={tableSort}
                      setTableSort={setTableSort}
                      selectedColumnId={selectedColumnId}
                      setSelectedColumnId={setSelectedColumnId}
                    >
                      <HeaderDropDown
                        isLastColumn={lastColumnHeader === column.Header}
                        columnHeader={column.Header}
                        isPinned={isPinned}
                        handlePin={handlePin}
                        handleHideColumn={() => {
                          handleHideColumn(column);
                          setSelectedColumnId(null);
                        }}
                        handleMouseIn={() => setSelectedColumnId(column.id)}
                        handleMouseOut={() => setSelectedColumnId(null)}
                      />
                    </DataHeader>
                  );
                })}
              </div>
            ))}
          </TableHeaders>
          <div {...getTableBodyProps()} className="body">
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <div
                  key={`tbrh:${index}`}
                  {...row.getRowProps()}
                  data-testid="dashboard-table-row"
                >
                  {row.cells.map((cell) => {
                    if (
                      hiddenColumns.some((c) => c.columnId === cell.column.id)
                    ) {
                      return;
                    }

                    const onTopicAiDashboard = router.pathname
                      .split('/')
                      .includes('trends-ai');

                    const onCommentsList =
                      tab === 'comments-list' && sliderTab === 'comments';
                    const onManageTopics =
                      tab === 'comments-list' && sliderTab === 'manageTopics';

                    const contributionId = cell.row.original._id;

                    const topicNameUnderscore = cell.row.original.topic;

                    const { question } = cell.row.original;

                    const isHighlighted = onTopicAiDashboard
                      ? onCommentsList || onManageTopics
                        ? `${topicNameUnderscore} ${contributionId} ${question}` ===
                          id
                        : topicNameUnderscore === id
                      : false;

                    return (
                      <TableCell
                        key={`td:${index}`}
                        type={tableType}
                        cell={cell}
                        isHighlighted={isHighlighted}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </TableContainer>
        {(isFetching || fetching || loading) && (
          <LoadRing loadingMessage="Loading" />
        )}
      </Wrapper>
    </>
  );
};

export { Table };
