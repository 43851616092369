// External Libraries
import * as React from 'react';
// Types
import { FeedbackMessageProps } from './types';
// Components
import { Header, Footer } from './components';
// Styles
import {
  Wrapper,
  Content,
  Title,
  Message,
  Bold,
  ImageContainer,
  StyledCircleCheckIcon,
  StyledCircleCancelIcon,
} from './FeedbackMessage.styles';

export const FeedbackMessage: React.FC<FeedbackMessageProps> = ({
  groupName,
  messageType,
  closeSidePanel,
  setCurrentTab,
}) => {
  const handleGoToGroups = () => {
    setCurrentTab(3);
    closeSidePanel();
  };
  return (
    <Wrapper data-testid="FeedbackMessage-Wrapper">
      <Header closeSidePanel={closeSidePanel} />
      {messageType === 'success' ? (
        <Content>
          <Title data-testid="Title-Success">
            <StyledCircleCheckIcon data-testid="Circle-Check-Icon" />
            Group created
          </Title>
          <Message data-testid="Message-Success">
            <p>Congratulations! Your group </p>
            <Bold data-testid="Bold-Group-Name">{groupName}</Bold>
            <p> has been created.</p>
          </Message>
          <ImageContainer data-testid="Image-Container-Success">
            <img
              src="/static/illustrations/dashboard_email-success.png"
              width="320"
              height="320"
            />
          </ImageContainer>
        </Content>
      ) : (
        <Content>
          <Title data-testid="Title-Error">
            <StyledCircleCancelIcon data-testid="Circle-Cancel-Icon" />
            Error
          </Title>
          <Message data-testid="Message-Error">
            <p>An error occured.</p>
          </Message>
          <ImageContainer data-testid="Image-Container-Error">
            <img
              src="/static/illustrations/dashboard_error.png"
              width="340"
              height="300"
            />
          </ImageContainer>
        </Content>
      )}
      <Footer
        leftButtonLabel="Close"
        onLeftButtonClick={() => closeSidePanel()}
        rightButtonLabel="Go to Groups"
        onRightButtonClick={handleGoToGroups}
      />
    </Wrapper>
  );
};
