import React from 'react';
import { PlusIcon } from 'Icons';
import {
  Wrapper,
  GreenButtonWithAddIcon,
  ApplyFiltersButton,
} from './ModalFooter.styles';

interface ModalFooterProps {
  areFilterRowsValid: () => boolean;
  addFilter: () => void;
  onApplyFilters: () => void;
}

export default function ModalFooter({
  addFilter,
  areFilterRowsValid,
  onApplyFilters,
}: ModalFooterProps) {
  return (
    <Wrapper>
      <GreenButtonWithAddIcon
        onClick={addFilter}
        data-gi="globalFiltersV2-add-filter-btn"
      >
        <PlusIcon />
        <span>Add filter</span>
      </GreenButtonWithAddIcon>
      <ApplyFiltersButton
        data-onboarding="dashboard-apply-filter"
        disabled={!areFilterRowsValid()}
        onClick={onApplyFilters}
        data-gi="globalFiltersV2-apply-filters-btn"
      >
        Apply filters
      </ApplyFiltersButton>
    </Wrapper>
  );
}
