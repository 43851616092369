import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 2.35rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey[600]}`};
  max-height: 30%;
  overflow-y: auto;
`;

export const Label = styled.p``;

export const FiltersWrapper = styled.li`
  list-style: none;
  margin-bottom: 2rem;
`;

export const FilterRow = styled.ul`
  margin: 0;
  margin-bottom: 1.2rem;
  padding: 0;
  display: flex;
`;

export const FilterRowLabel = styled.span`
  text-transform: capitalize;
  font-size: 1rem;
  opacity: 0.4;
  font-weight: ${({ theme }) => theme.fontWeights.semi};
  flex: 1;
`;

export const FilterRowValueContainer = styled.div`
  flex: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
