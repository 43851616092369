import styled, { css } from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0.9rem 0.4rem;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 16rem;
  > li:last-child {
    margin: 0 1rem;
  }
`;

export const ConfirmButton = styled.button<{ isDisabled: boolean }>`
  width: 100%;
  min-height: 2.5rem;
  font-size: 1rem;
  font-weight: 700;
  border: none;
  cursor: pointer;
  ${({ theme, isDisabled }) =>
    isDisabled
      ? css`
          color: ${theme.colors.grey['A700']};
          background: ${theme.colors.grey['A200']};
        `
      : css`
          color: ${theme.colors.white[500]};
          background: ${theme.colors.green[500]};
        `}
`;
