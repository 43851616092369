export const LAST_WEEK = 'Last week';
export const LAST_MONTH = 'Last month';
export const LAST_3_MONTH = 'Last 3 months';
export const LAST_6_MONTH = 'Last 6 months';
export const LAST_YEAR = 'Last year';
export const WHOLE_PROJECT = 'Whole project';
export const SPECIFY_DATES = 'Specify dates';

export const predefinedOptions = [
  LAST_WEEK,
  LAST_MONTH,
  LAST_3_MONTH,
  LAST_6_MONTH,
  LAST_YEAR,
  WHOLE_PROJECT,
  SPECIFY_DATES,
];
