import { FilterOption, FilterRowMetadata } from 'Shared/types/filters';
import { mapValuesToOptions } from 'Pages/dashboard/contributions/utils';

interface AvailableAnswersProps {
  filterOption: FilterOption;
  additionalFilterable: { [key: string]: string[] };
  allAnswers: { [key: string]: unknown[] };
  rowMetadata?: FilterRowMetadata;
}

export default function getAvailableAnswers({
  filterOption,
  additionalFilterable,
  allAnswers,
  rowMetadata = {},
}: AvailableAnswersProps) {
  if (!filterOption) return [];
  if (Object.keys(rowMetadata).length) {
    const { matrix, budget, priority } = rowMetadata;
    if (matrix) return matrix.columns;
    if (budget) return budget.budgetOptions;
    if (priority) return priority.priorityRanks;
  }
  const { rules, property, questionContent } = filterOption;
  // use possibleValues if available
  const { possibleValues } = rules;
  if (possibleValues) return mapValuesToOptions(possibleValues);

  // use questions options if available
  const options = questionContent
    ? questionContent.options || questionContent.values
    : null;
  if (options) return mapValuesToOptions(options as string[]);

  // use filterable if available
  const filterable = additionalFilterable[property];
  if (filterable) return mapValuesToOptions(filterable);

  // build options from all answers
  const keyForAnswer = questionContent ? questionContent.questionId : property;
  const answer = allAnswers[keyForAnswer];
  if (!answer) return [];
  const dedupeAnswer = Array.from(new Set(answer.flat())).filter(Boolean);
  const sortedAnswer = dedupeAnswer.sort((a, b) => {
    if (typeof a === 'number' && typeof b === 'number') return a - b;
    return String(a).localeCompare(String(b), 'en', { sensitivity: 'base' });
  });
  return mapValuesToOptions(sortedAnswer as string[] | number[]);
}
