import * as React from 'react';
import { useRouter } from 'next/router';
import { makeRedirectDomain } from 'Client/utils/url';
import { useProject } from 'Client/utils/hooks';
import { redirectToSliderTab } from 'Client/pages/dashboard/utils/tabUrlHelpers';
import { Wrapper } from './TableCell.styles';
import { TableCellProps } from './types';

const TableCell: React.FC<TableCellProps> = ({
  children,
  type,
  cell,
  isHighlighted = false,
  ...props
}: TableCellProps) => {
  const { _id } = cell?.row?.original || {};
  const { id, features } = useProject();
  const router = useRouter();
  const { tab } = router.query;
  const redirectDomain = makeRedirectDomain(id);

  const handleCellClick = () => {
    if (
      cell?.column?.id === 'row-selection' ||
      cell?.column?.id === 'mapLink' ||
      type === 'groups' ||
      type === 'visitors'
    )
      return;

    if (type === 'trends-ai') {
      const {
        original: { topic, _id, question },
      } = cell?.row || {};

      if (tab === 'comments-list') {
        redirectToSliderTab({
          newTab: 'comments',
          page: 'trends-ai',
          router,
          id: `${topic} ${_id} ${question}`,
        });

        return;
      }

      if (tab === 'topics') {
        redirectToSliderTab({
          newTab: 'topicSummary',
          page: 'trends-ai',
          router,
          id: topic,
        });

        return;
      }
    }

    document.location.href = (() => {
      if (type === 'contributions') {
        return features.newDashboard
          ? `http://${redirectDomain}/v5/dashboard/contributions/${_id}`
          : `http://${redirectDomain}/v4/dashboard/contribution/${_id}`;
      }

      if (type === 'respondents') {
        return `http://${redirectDomain}/v5/dashboard/respondents/${_id}`;
      }
    })();
  };

  return (
    <Wrapper
      onClick={handleCellClick}
      type={type}
      isHighlighted={isHighlighted}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

export { TableCell };
