import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/react';

export const getPivotOptions = async (projectId: string) => {
  try {
    const result = await fetch(
      `/api/external/project/${projectId}/chartdata/pivotOptions`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    );
    const { data, canDisplaySpecial } = await result.json();
    if (!data) return [];

    return { pivotOptions: data, canDisplaySpecial };
  } catch (error) {
    captureException(
      `error in getPivotOptions @ getPivotOptions.ts : ${error}`
    );
    return { pivotOptions: [], canDisplaySpecial: false };
  }
};
