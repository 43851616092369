import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';

export const BaseHeaderButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  svg {
    stroke-width: 2;
    width: 0.65rem;
    height: 0.65rem;
    margin-right: 0.5rem;
  }
`;

export const DialogHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => css`
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        flex-flow: wrap;
        justify-content: center;
        span {
          margin-bottom: 2rem;
        }
      `
    )}
  `}
`;

export const HeaderTitle = styled.span`
  font-weight: 600;
  text-transform: uppercase;
`;

export const HeaderOptions = styled.div`
  display: flex;
  button {
    cursor: pointer;
  }
`;

export const GreenButtonWithCancelIcon = styled(BaseHeaderButton)`
  color: ${({ theme }) => theme.colors.green['A700']};
  span {
    font-size: 0.9rem;
    font-weight: bold;
  }
`;

export const HeaderCloseButton = styled(BaseHeaderButton)`
  ${({ theme }) => css`
    color: ${theme.colors.grey[900]};
    margin-left: 1.5rem;
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        position: absolute;
        right: 0.5rem;
        top: 1rem;
        margin: 0;
      `
    )}
  `}
`;
