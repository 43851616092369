import styled, { css } from 'styled-components';

export const Checkbox = styled.input`
  ${({ theme }) => css`
    @supports (-webkit-appearance: none) or (-moz-appearance: none) {
      height: 1.2rem;
      width: 1.2rem;
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
      display: inline-block;
      position: relative;
      transition: background 0.15s, border-color 0.3s, box-shadow 0.2s;
      border-radius: 0.125rem;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0.4rem;
        height: 0.7rem;
        border: 0.125rem solid ${theme.colors.white[500]};
        border-top: 0;
        border-left: 0;
        left: 0.25rem;
        top: 0.0625rem;
        transform: rotate(46deg);
      }
      border: ${`.125rem solid ${theme.colors.grey[500]}`};
      background: ${theme.colors.white[500]};
      &:checked {
        background: ${theme.colors.green[500]};
        border-color: ${theme.colors.green[500]};
      }
      &:indeterminate {
        background: ${theme.colors.grey[500]};
        border-color: ${theme.colors.grey[500]};
        &:after {
          content: '';
          border: 0.125rem solid white;
          background-color: white;
          transform: rotate(0deg);
          width: 90%;
          height: 0;
          position: unset;
          margin: 0.35rem auto;
          transition: none;
        }
      }
    }
    &:hover {
      &:not(:checked) {
        border-color: ${theme.colors.grey[900]};
      }
    }
  `}
`;
