import * as React from 'react';
import { ContributionsSummary } from 'Shared/types/dashboard';
import { CommentIcon, AgreeIcon /* ReplyIcon */ } from 'Icons';
import { theme } from 'Theme';
import { SummaryData } from '../types';

export const mapFooterData = ({
  totalComments,
  totalAgreements,
}: ContributionsSummary): Array<SummaryData> => {
  return [
    {
      icon: <CommentIcon color={theme.colors.grey[600]} />,
      label: 'Comments',
      value: totalComments,
    },
    {
      icon: <AgreeIcon color={theme.colors.grey[600]} />,
      label: 'Agreements',
      value: totalAgreements,
    },
  ];
};
