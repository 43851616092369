export const checkAllAutoToggle = (
  flatRows: Array<string | undefined>,
  selectedFlatRows: Array<string | undefined>,
  onAllRowsCheckboxToggle: (arg: boolean) => void,
  isAllRowsCheckboxSelected: boolean
) => {
  let minRowsNumber = 100;
  if (flatRows.length < 100) {
    minRowsNumber = 1;
  }

  if (
    flatRows.length !== selectedFlatRows.length &&
    isAllRowsCheckboxSelected
  ) {
    onAllRowsCheckboxToggle(true);
    return true;
  }

  if (
    flatRows.length >= minRowsNumber &&
    flatRows.length === selectedFlatRows.length &&
    !isAllRowsCheckboxSelected
  ) {
    onAllRowsCheckboxToggle(false);
    return false;
  }
};
