import { SentimentsOverview } from 'Shared/types/dashboard';
import { sentimentsData } from 'Pages/dashboard/contributions/constants';
import { Sentiment } from '../types';

export const mapSentimentsData = (
  sentiments: SentimentsOverview
): Array<Sentiment> => {
  const mappedValues = sentimentsData.map((sentiment) => {
    return {
      ...sentiment,
      value: sentiments[sentiment.id],
    };
  });
  return mappedValues;
};
