import styled, { css } from 'styled-components';

export const StyledButton = styled.button<{ isLastButton: boolean }>`
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 0.5rem;
  ${({ theme, isLastButton, disabled }) => css`
    background: ${theme.colors.white[500]};
    color: ${theme.colors.green[500]};
    font-weight: ${theme.fontWeights.bold};
    ${isLastButton &&
    css`
      color: ${theme.colors.black[500]};
      margin-left: 1rem;
    `}
    ${disabled &&
    css`
      color: grey;
      cursor: default;
    `}
  `}
`;
