import React from 'react';
import { FilterRow, FilterOption, InputType } from 'Shared/types/filters';
import { mapValuesToOptions } from 'Client/pages/dashboard/contributions/utils';
import GroupContainer from './GroupContainer';
import PropertiesContainer from './PropertiesContainer';
import ConditionContainer from './ConditionContainer';
import ValueContainer from './ValueContainer';
import HelperContainer from './HelperContainer';

interface RowLayoutProps {
  row: FilterRow;
  filterOptions: FilterOption[];
  rowIndex: number;
  openToTop: boolean;
  updateRowGroup: (rowIndex: number, newGroup: string) => void;
  updateRowProperty: (rowIndex: number, newProperty: string) => void;
  updateRowCondition: (rowIndex: number, newCondition: string) => void;
  updateRowValue: (rowIndex: number, newValue: string) => void;
  updateRowMetadata: (
    rowIndex: number,
    keyToUpdate: string,
    newValue: unknown
  ) => void;
}

const RowLayout: React.FC<RowLayoutProps> = ({
  row,
  filterOptions,
  updateRowGroup,
  updateRowProperty,
  updateRowCondition,
  updateRowValue,
  updateRowMetadata,
  ...additionalProps
}: RowLayoutProps) => {
  const propertiesOptions = mapValuesToOptions(
    filterOptions.map((option) => option.property)
  );
  const rowLayout = (() => {
    if (
      row.metadata?.budget ||
      row.metadata?.matrix ||
      row.metadata?.priority
    ) {
      return 'helperContainerAtMiddle';
    }
    return 'default';
  })();

  const groupContainerProps = {
    ...additionalProps,
    rowGroup: row.group,
    updateRowGroup,
  };

  const propertiesContainerProps = {
    ...additionalProps,
    propertiesOptions,
    rowProperty: row.property,
    updateRowProperty,
  };

  const conditionContainerProps = {
    ...additionalProps,
    rowCondition: row.condition,
    conditionOptions: mapValuesToOptions(row.possibleConditions),
    updateRowCondition,
  };

  const valueContainerProps = {
    ...additionalProps,
    inputType: row.inputType as InputType,
    value: row.value,
    isMulti: row.metadata.priority ? false : true,
    answersOptions: mapValuesToOptions(row.possibleValues as string[]),
    updateValue: updateRowValue,
  };

  const helperContainerProps = {
    ...additionalProps,
    row,
    updateRowMetadata,
  };

  const layouts = {
    default: (
      <>
        <GroupContainer {...groupContainerProps} />
        <PropertiesContainer {...propertiesContainerProps} />
        <ConditionContainer {...conditionContainerProps} />
        <ValueContainer {...valueContainerProps} />
      </>
    ),
    helperContainerAtMiddle: (
      <>
        <GroupContainer {...groupContainerProps} />
        <PropertiesContainer {...propertiesContainerProps} />
        <HelperContainer {...helperContainerProps} />
        <ConditionContainer {...conditionContainerProps} />
        <ValueContainer {...valueContainerProps} />
      </>
    ),
  };

  return <>{layouts[rowLayout]}</>;
};

export default RowLayout;
