import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isLastColumn: boolean }>`
  min-width: 12.5rem;
  max-width: 25rem;
  min-height: 2.5rem;
  position: absolute;
  top: 80%;
  left: 0;
  margin: 0.8rem 0.2rem;
  border-radius: 0.3rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 1.5rem rgb(0 0 0 / 10%);
  ${({ theme, isLastColumn }) => css`
    background: ${theme.colors.white[500]};
    ${isLastColumn &&
    css`
      left: -100%;
    `}
  `}
`;

export const Header = styled.div`
  min-height: 4rem;
  min-width: 12.5rem;
  max-width: 25rem;
  width: max-content;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[600]};
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeaderTitle = styled.span`
  flex: 10;
  width: 100%;
  white-space: normal;
  font-weight: 700;
  margin: 0 1rem 0 0.8125rem;
  ${({ theme }) => css`
    color: ${theme.colors.black[500]};
    font-size: ${theme.texts.secondaryText.fontSize};
  `}
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex: 3;
  svg {
    color: ${({ theme }) => theme.colors.black[500]};
    cursor: pointer;
    margin: 0 0.2rem;
    transform: scaleY(1.2);
  }
`;

export const HideColumnWrapper = styled.div`
  height: fit-content;
`;

export const PinWrapper = styled.div`
  height: 1rem;
  svg {
    width: 0.93rem;
    height: 0.93rem;
    cursor: pointer;
    margin-right: 0.5rem;
  }
`;
