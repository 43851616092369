import React from 'react';
import { InputType } from 'Shared/types/filters';
import { OptionItem } from 'Client/types';
import CustomSelect from '../CustomSelect/CustomSelect';
import CustomTextField from '../CustomTextField/CustomTextField';
import CustomNumericField from '../CustomNumericField/CustomNumericField';
import { ValueContainerWrapper } from './styles';

interface ValueContainerProps {
  rowIndex: number;
  inputType: InputType;
  value: unknown;
  openToTop: boolean;
  answersOptions: OptionItem[];
  isMulti: boolean;
  updateValue: (rowIndex: number, value: unknown) => void;
}

function renderInput(inputType: InputType, props: ValueContainerProps) {
  if (inputType === 'select') {
    const { answersOptions, value, isMulti } = props;
    const mappedValue = (() => {
      if (!value) return [];
      if (Array.isArray(value)) {
        return value
          .map((value) => {
            const option = answersOptions.find(
              (option) => option.value === value
            );
            if (!option) return null;
            return option;
          })
          .filter(Boolean);
      }
      return [answersOptions.find((option) => option.value === value)];
    })();
    return (
      <CustomSelect
        openToTop={props.openToTop}
        options={props.answersOptions}
        value={mappedValue}
        placeholder="Select an option"
        isMulti={isMulti}
        onChange={(value: OptionItem | OptionItem[]) => {
          if (!value) {
            props.updateValue(props.rowIndex, []);
            return;
          }
          if (Array.isArray(value)) {
            const valueToSave = value.map(({ value }) => value);
            props.updateValue(props.rowIndex, valueToSave);
            return;
          }
          const { value: valueToSave } = value;
          props.updateValue(props.rowIndex, valueToSave);
        }}
      />
    );
  }
  if (inputType === 'textfield') {
    const value = (() => {
      if (!props.value) return '';
      if (Array.isArray(props.value)) return '';
      return props.value;
    })();
    return (
      <CustomTextField
        value={value as string}
        updateValue={(value) => props.updateValue(props.rowIndex, value)}
      />
    );
  }
  if (inputType === 'numericField') {
    const value = (() => {
      if (!props.value) return 0;
      if (Array.isArray(props.value)) return 0;
      return props.value;
    })();
    return (
      <CustomNumericField
        value={value as number}
        updateValue={(value) => props.updateValue(props.rowIndex, value)}
      />
    );
  }
  if (inputType === 'booleanField') {
    return (
      <CustomSelect
        openToTop={false}
        options={[]}
        value={[]}
        placeholder=""
        isMulti={true}
        onChange={() => {}}
        isDisabled={true}
      />
    );
  }
  return null;
}

export default function ValueContainer(props: ValueContainerProps) {
  const { inputType } = props;
  return (
    <ValueContainerWrapper
      isDisabled={inputType === 'booleanField'}
      data-gi="globalFiltersV2-value-container"
    >
      {renderInput(inputType, props)}
    </ValueContainerWrapper>
  );
}
