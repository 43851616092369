import styled from 'styled-components';
import { ApplyFilterButton } from '../../DateFilter/components/SelectComponents/selectComponents.styles';

export const OptionWrapper = styled.div`
  input {
    min-height: 1rem;
    min-width: 1rem;
    margin-right: 0.6rem;
  }
  .disabled {
    &:hover {
      pointer-events: none;
    }
  }
  label {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MenuButton = styled(ApplyFilterButton)``;

export const FormatLabelWrapper = styled.div`
  padding: 1rem 3.75rem;
  span {
    font-weight: 400;
    font-size: 0.7rem;
    color: ${({ theme }) => theme.colors.grey[800]};
  }
`;
