import * as React from 'react';
import fetch from 'isomorphic-unfetch';
import { useRouter } from 'next/router';
import { addNewTopFilter, getTopFiltersFromUrl } from 'Pages/dashboard/utils';
import {
  Wrapper,
  SentimentBox,
  SentimentCircle,
  SentimentValue,
  SentimentLabel,
} from './Sentiments.styles';
import { Sentiment } from './types';
import { mapSentimentsData, getNewSentiments } from './utils';

export const Sentiments: React.FC = () => {
  const [sentimentsOverview, setSentimentsOverview] = React.useState<
    Array<Sentiment>
  >([]);
  const router = useRouter();
  const topFilters = getTopFiltersFromUrl({ router });
  const topFiltersSentiment = topFilters.sentiment;

  React.useEffect(() => {
    const getInitialData = async () => {
      try {
        const res = await fetch('/api/external/project/sentiments', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const json = await res.json();
        const sentiments = mapSentimentsData(json);
        setSentimentsOverview(sentiments);
      } catch (error) {
        console.log(error);
      }
    };

    getInitialData();
  }, []);

  return (
    <Wrapper data-testid="dashboard-sentiment-wrapper">
      {sentimentsOverview.map((sentiment, index) => {
        const isSelected = topFiltersSentiment?.includes(sentiment.id);
        return (
          <SentimentBox
            selected={isSelected}
            onClick={() => {
              const sentiments = getNewSentiments({
                sentiment,
                selectedSentiments: topFiltersSentiment,
              });
              addNewTopFilter({
                page: 'contributions',
                router,
                newFilter: { sentiment: sentiments },
              });
            }}
            color={sentiment.color}
            key={`${sentiment.label}-0${index}`}
            data-testid={`00${index}`}
          >
            {!isSelected && <SentimentCircle color={sentiment.color} />}
            <SentimentValue>{sentiment.value}</SentimentValue>
            <SentimentLabel>{sentiment.label}</SentimentLabel>
          </SentimentBox>
        );
      })}
    </Wrapper>
  );
};
