import * as React from 'react';
import { RadioButtonProps } from '../types';
import { Wrapper, Whitespace, GreenInner } from './RadioButton.styles';

export const RadioButton: React.FC<RadioButtonProps> = ({
  selected,
  onClick,
}) => {
  const [mouseDown, setMouseDown] = React.useState<boolean>(false);

  return (
    <Wrapper
      data-testid="Radio-Wrapper"
      onClick={onClick}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
    >
      <Whitespace selected={selected} mouseDown={mouseDown}>
        <GreenInner selected={selected} mouseDown={mouseDown} />
      </Whitespace>
    </Wrapper>
  );
};
