import styled, { css } from 'styled-components';
import { GreenInnerProps, WhitespaceProps } from '../types';

export const Wrapper = styled.div`
  width: 1.2875rem;
  height: 1.2875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8125rem;
`;
export const Whitespace = styled.div<WhitespaceProps>`
  ${({ mouseDown }) =>
    mouseDown &&
    css`
      filter: brightness(1.2);
    `}
  border: ${({ selected }) =>
    selected ? '0.125rem solid#00AC5D' : '0.125rem solid #666666'};
  border-radius: 100%;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const GreenInner = styled.div<GreenInnerProps>`
  border-radius: 100%;
  width: 0.6rem;
  height: 0.6rem;
  background-color: ${({ selected }) => (selected ? '#00AC5D' : 'transparent')};
`;
