import { gql } from '@apollo/client';

export const CREATE_GROUP_MUTATION = gql`
  mutation CreateNewGroup($createGroupInput: CreateGroupInput) {
    createNewGroup(createGroupInput: $createGroupInput) {
      _id
      reference
      type
      documentsIds
      projectId
      name
      createdAt
      updatedAt
      filters
    }
  }
`;
