import React from 'react';
import { OptionItem } from 'Client/types';
import { mapValuesToOptions } from 'Pages/dashboard/contributions/utils';
import CustomSelect from '../CustomSelect/CustomSelect';
import { PropertiesContainerWrapper } from './styles';

interface PropertiesContainerProps {
  rowIndex: number;
  propertiesOptions: OptionItem[];
  openToTop: boolean;
  rowProperty: string;
  updateRowProperty: (rowIndex: number, newProperty: string) => void;
}

export default function PropertiesContainer({
  rowIndex,
  rowProperty,
  openToTop,
  propertiesOptions,
  updateRowProperty,
}: PropertiesContainerProps) {
  return (
    <PropertiesContainerWrapper data-gi="globalFiltersV2-properties-container">
      <CustomSelect
        openToTop={openToTop}
        options={propertiesOptions}
        value={mapValuesToOptions([rowProperty])}
        onChange={({ value }) => updateRowProperty(rowIndex, value)}
      />
    </PropertiesContainerWrapper>
  );
}
