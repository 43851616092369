import styled from 'styled-components';

export const ChartContainer = styled.div`
  min-width: 47%;
  min-height: max-content;
  min-height: 37.5rem;
  padding: 1.5rem;
  margin: 1rem;
  flex-basis: 0%;
  flex-basis: auto;
`;
