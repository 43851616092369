import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
/* types */
import { FilterRow, UrlFilter } from 'Shared/types/filters';
import { GlobalFiltersProps } from './types';
/* helpers */
import getDefaultFilterRow from './utils/getDefaultFilterRow';
import areFilterRowsValid from './utils/areFilterRowsValid';
import mapUrlToFilters from './utils/mapUrlToFilters';
import getGlobalFiltersInternals from './helpers';
/* components */
import { Dialog, DialogContent } from './GlobalFilters.styles';
import ModalHeader from './components/ModalHeader/ModalHeader';
import Row from './components/Row/Row';
import EmptyFilter from './components/EmptyFilter/EmptyFilter';
import ModalFooter from './components/ModalFooter/ModalFooter';

export default function GlobalFilters({
  isOpen,
  onModalClose,
  pageType,
  filterOptions,
  allAnswers,
  additionalFilterable,
}: GlobalFiltersProps) {
  const router = useRouter();
  const { query } = router;
  const [filtersRow, setFiltersRow] = useState<FilterRow[]>([]);

  const {
    addNewFilterRow,
    deleteFilterRow,
    updateGroupForFilterRow,
    updatePropertyForFilterRow,
    updateConditionForFilterRow,
    updateValueForFilterRow,
    updateMetadataForFilterRow,
    applyFilters,
    clearFilters,
  } = getGlobalFiltersInternals({
    filtersRow,
    setFiltersRow,
    filterOptions,
    allAnswers,
    additionalFilterable,
    router,
    onModalClose,
  });

  useEffect(() => {
    if (!filterOptions || !filterOptions.length) return;
    if (query?.nestedFilters) {
      const parsedNestedFilters = JSON.parse(
        query.nestedFilters as string
      ) as UrlFilter[];
      const mappedFilters = mapUrlToFilters({
        urlFilters: parsedNestedFilters,
        filterOptions,
        allAnswers,
        additionalFilterable,
      });
      setFiltersRow(mappedFilters);
      return;
    }
    const initialFilterRow = getDefaultFilterRow({
      filterOptions,
      additionalFilterable,
      allAnswers,
    });
    if (!initialFilterRow) return;
    setFiltersRow([initialFilterRow]);
  }, [query.nestedFilters, filterOptions, additionalFilterable, allAnswers]);

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm" onClose={onModalClose}>
      <ModalHeader
        pageType={pageType}
        onModalClose={onModalClose}
        resetFilters={clearFilters}
      />
      <DialogContent>
        {filtersRow.length ? (
          filtersRow.map((row, index) => (
            <Row
              key={`${row.property}-${row.inputType}-${index}`}
              deleteRow={() => deleteFilterRow(index)}
              row={row}
              rowIndex={index}
              filterOptions={filterOptions}
              openToTop={
                filtersRow.length >= 4 && index > filtersRow.length - 3
              }
              updateRowGroup={updateGroupForFilterRow}
              updateRowProperty={updatePropertyForFilterRow}
              updateRowCondition={updateConditionForFilterRow}
              updateRowValue={updateValueForFilterRow}
              updateRowMetadata={updateMetadataForFilterRow}
            />
          ))
        ) : (
          <EmptyFilter />
        )}
      </DialogContent>
      <ModalFooter
        addFilter={addNewFilterRow}
        onApplyFilters={applyFilters}
        areFilterRowsValid={() => areFilterRowsValid(filtersRow)}
      />
    </Dialog>
  );
}
