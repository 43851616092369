import * as React from 'react';
import { components } from 'react-select';
import {
  OptionWrapper,
  MenuButton,
  FormatLabelWrapper,
} from './CustomComponents.styles';

export const customOptionComponent = () => {
  const Option = (props) => (
    <OptionWrapper>
      <components.Option {...props}>
        <input
          type="radio"
          defaultChecked={props.isSelected}
          className={props.isDisabled && 'disabled'}
          onClick={(e) => {
            if (props.isDisabled) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </OptionWrapper>
  );

  return Option;
};

export const customMenuComponent = ({ onApplyFilters }) => {
  const MenuComponent = (props) => (
    <>
      <components.Menu {...props}>
        {props.children}
        <MenuButton onClick={onApplyFilters}>Apply filters</MenuButton>
      </components.Menu>
    </>
  );

  return MenuComponent;
};

export const formatGroupLabel = (data) => {
  if (data.label)
    return (
      <FormatLabelWrapper>
        <span>{data.label}</span>
      </FormatLabelWrapper>
    );
  return null;
};
