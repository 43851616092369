import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Label = styled.span`
  position: absolute;
  height: 3rem;
  width: 20rem;
  border-radius: 0.15rem;
  bottom: 150%;
  right: -56%;
  padding: 0.5rem 0.65rem;
  z-index: 1;
  text-align: center;
  ${({ theme }) => css`
    font-size: ${theme.texts.smallLabel.fontSize};
    font-weight: ${theme.fontWeights.semi};
    background: ${theme.colors.grey[800]};
    color: ${theme.colors.white[500]};
  `}
`;
