import { NOT_NULL, NULL } from 'Shared/constants';
import {
  GetFiltersWithValuesProps,
  GetFiltersWithValuesResponse,
  FilterValues,
} from '../types';

const validateFilters = (filter: FilterValues): boolean => {
  if (Array.isArray(filter)) {
    return Boolean(filter.length);
  }
  return Boolean(filter);
};

export const getFiltersWithValue = ({
  filters,
}: GetFiltersWithValuesProps): GetFiltersWithValuesResponse => {
  const { topFilters, nestedFilters } = filters;
  const topFiltersWithValues = Object.keys(topFilters || {})
    .filter((topFilter) => validateFilters(topFilters[topFilter]))
    .map((topFilter) => ({ label: topFilter, value: topFilters[topFilter] }));
  const nestedFiltersWithValues = (nestedFilters || []).map((nestedFilter) => {
    const { property, value, filterCondition } = nestedFilter;
    if (filterCondition === NOT_NULL || filterCondition === NULL) {
      return {
        label: property,
        value: filterCondition,
      };
    }
    return {
      label: property,
      value,
    };
  });
  return [...topFiltersWithValues, ...nestedFiltersWithValues];
};
