import * as React from 'react';
import uniqBy from 'lodash.uniqby';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useProject } from 'Client/utils/hooks';
import { PivotCriteria } from 'Shared/types/dashboard';
import {
  getChartWidthValue,
  generateChartTypes,
  generateLayout,
  toggleChartWidth,
  toggleMapSize,
  MAX_WIDTH,
} from './utils';
import { getPivotOptions } from './services';
import {
  ChartsContainerProps,
  ChartTypes,
  SectionChart,
  PivotData,
} from './types';
import { ChartContainer } from './ChartsContainer.styles';
import { ChartWrapper, SectionTitle } from './components';

export const ChartsContainer: React.FC<ChartsContainerProps> = ({
  data,
  dataType,
  pageType,
  appliedFilters,
  contributionsIds,
}: ChartsContainerProps) => {
  const { _id: projectId } = useProject();
  const Grid = WidthProvider(Responsive);
  const [pivotData, setPivotData] = React.useState<Array<PivotData>>([]);
  const [canDisplaySpecial, setCanDisplaySpecial] =
    React.useState<boolean>(false);
  const [chartTypes, setChartTypes] = React.useState<ChartTypes>({});
  const [pivotCriteria, setPivotCriteria] = React.useState<{
    [key: string]: PivotCriteria;
  }>({});
  const [sectionCharts, setSectionCharts] = React.useState<Array<SectionChart>>(
    []
  );

  React.useEffect(() => {
    const allSections = data.map((data) => data.section);

    const uniqueSections = uniqBy(allSections, 'title');

    const chartItemsBySection = uniqueSections.map((section) => {
      const itemsBySection = data.filter(
        (question) => question.section.title === section.title
      );
      const sectionLayout = generateLayout(itemsBySection);

      return {
        section,
        questions: itemsBySection,
        sectionLayout,
      };
    });

    setSectionCharts(chartItemsBySection);
  }, [dataType, data]);

  React.useEffect(() => {
    const invokeGetPivotOptions = async () => {
      const { pivotOptions, canDisplaySpecial } = (await getPivotOptions(
        projectId
      )) as { pivotOptions: Array<any>; canDisplaySpecial: boolean };
      setPivotData(pivotOptions);
      setCanDisplaySpecial(canDisplaySpecial);
    };

    invokeGetPivotOptions();
  }, [projectId]);

  React.useEffect(() => {
    setChartTypes(generateChartTypes(data));
  }, [data]);

  return (
    <div className="charts-container">
      {!sectionCharts.length
        ? []
        : sectionCharts.map((sectionChart, index) => {
            const { section, questions, sectionLayout } = sectionChart;

            return (
              <>
                {section.showSectionTitle && (
                  <SectionTitle
                    title={section.title}
                    isProposal={dataType === 'questions'}
                  />
                )}
                <Grid
                  isDraggable={false}
                  cols={{ lg: 2, md: 1, sm: 1, xs: 1, xxs: 1 }}
                  isBounded
                  layouts={{
                    lg: sectionLayout,
                  }}
                  draggableHandle=".drag"
                  breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                  key={section.title}
                  rowHeight={600}
                >
                  {questions.map((question) => (
                    <ChartContainer
                      data-testid="ChartsContainer-ChartContainer"
                      key={question.id}
                    >
                      <ChartWrapper
                        question={question}
                        contributionsIds={contributionsIds}
                        pivotData={pivotData}
                        canDisplaySpecial={canDisplaySpecial}
                        appliedFilters={appliedFilters}
                        changeWidth={(newWidth: number) => {
                          const newLayout = toggleChartWidth(
                            question.id,
                            sectionLayout,
                            newWidth
                          );
                          const newSectionCharts = [...sectionCharts];
                          newSectionCharts[index].sectionLayout = newLayout;
                          setSectionCharts(newSectionCharts);
                          return;
                        }}
                        toggleMapSize={(
                          newHeight: number,
                          newWidth: number
                        ) => {
                          const newLayout = toggleMapSize({
                            id: question.id,
                            layout: sectionLayout,
                            newSIZE: { h: newHeight, w: newWidth },
                          });
                          const newSectionCharts = [...sectionCharts];
                          newSectionCharts[index].sectionLayout = newLayout;
                          setSectionCharts(newSectionCharts);
                          return;
                        }} // improve
                        setChartTypes={setChartTypes}
                        chartTypes={chartTypes}
                        setPivotCriteria={setPivotCriteria}
                        pivotCriteria={pivotCriteria}
                        isMaxWidth={
                          getChartWidthValue(question.id, sectionLayout) ===
                          MAX_WIDTH
                        }
                        pageType={pageType}
                      />
                    </ChartContainer>
                  ))}
                </Grid>
              </>
            );
          })}
    </div>
  );
};
