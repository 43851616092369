import styled, { css } from 'styled-components';
import { readableColor, lighten } from 'polished/lib';

export const Wrapper = styled.div<{ isDashboard: boolean }>`
  ${({ isDashboard }) =>
    isDashboard &&
    css`
      position: absolute;
      left: -175%;
      z-index: 10;
    `}
`;

export const SelectedDates = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background-color: ${({ theme }) => theme.colors.black[100]};
  font-size: 1rem;
  outline: 0.0625rem solid #dfdfdf;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.05);

  span {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.black[500]};
    margin-right: 0.3125rem;

    &:not(:first-child) {
      margin-left: 1.5rem;
    }
  }
`;

export const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  outline: 0.0625rem solid #dfdfdf;
`;

export const Month = styled.div`
  min-width: 100%;
  margin: 0.625rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.colors.green[500]};
  max-width: 75%;

  svg {
    cursor: pointer;

    ${({ theme }) => css`
      &:focus {
        outline-color: ${theme.colorMappings.focusHighlight};
        outline-style: solid;
        outline-offset: 0.5rem;
        outline-width: 0.1875rem;
      }
    `}
  }
`;

export const Week = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 0.5rem;
`;

export const Day = styled.div<{
  isSelected?: boolean;
  isToDate?: boolean;
  isBetween?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${100 / 7}%;
  height: 1.875rem;
  cursor: pointer;

  font-size: 0.75rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black[500]};

  ${({ theme }) => css`
    &:focus {
      outline-color: ${theme.colorMappings.focusHighlight};
      outline-style: solid;
      outline-offset: 0.1rem;
      outline-width: 0.1875rem;
    }
  `}

  ${({ isSelected, isToDate, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.green[500]};
      color: ${readableColor(theme.colors.green[500])};
      border-radius: ${isToDate
        ? '0 0.1875rem 0.1875rem 0'
        : '0.1875rem 0 0 0.1875rem'};
    `}

  ${({ isBetween, theme }) =>
    isBetween &&
    css`
      background-color: ${lighten(0.3, theme.colors.green[500])};
      color: ${readableColor(lighten(0.3, theme.colors.green[500]))};
    `}
`;
