import { FilterRow } from 'Shared/types/filters';

export default function areFilterRowsValid(filterRows: FilterRow[]) {
  if (!filterRows || !filterRows?.length) return false;
  const mappedFilterRowsValues = filterRows.map((row) => row.value);
  const areThereEmptyValues = mappedFilterRowsValues.some((value) => {
    if (value === '' || value === null || value === undefined) return true;
    if (Array.isArray(value) && !value.length) return true;
    return false;
  });
  if (areThereEmptyValues) return false;
  const areThereInvalidValuesOnMetadata = filterRows.some((filterRow) => {
    if (!filterRow.metadata) return false;
    const { matrix, budget, priority } = filterRow.metadata;
    if (
      (matrix && !matrix.topicValue) ||
      (budget && !budget.budgetValue) ||
      (priority && !priority.priorityValue)
    )
      return true;
  });
  if (areThereInvalidValuesOnMetadata) return false;
  return true;
}
