import * as React from 'react';
import { ButtonItemsProps } from '../../types';
import { StyledButton } from './ButtonItem.styles';

export const ButtonItem: React.FC<ButtonItemsProps> = ({
  buttonLabel,
  openDropDown,
  isLastButton,
  children,
  testid,
  onClick,
}: ButtonItemsProps) => {
  return (
    <>
      <StyledButton
        data-testid={testid}
        isLastButton={isLastButton}
        onClick={onClick}
      >
        {buttonLabel}
      </StyledButton>
      {openDropDown === buttonLabel && children}
    </>
  );
};
