import * as React from 'react';
import { DropDownListProps } from '../../types';
import { DropDownListItem } from '../DropDownListItem';
import { List, ConfirmButton } from './DropDownList.styles';

export const DropDownList: React.FC<DropDownListProps> = ({
  columnsToUnHide,
  hiddenColumns,
  onItemToggle,
  onConfirm,
}: DropDownListProps) => {
  const isButtonDisabled = Boolean(!columnsToUnHide.length);
  return (
    <>
      <List data-testid="DropDownList-List">
        {hiddenColumns.map((column) => {
          const isChecked = columnsToUnHide.find(
            (columnToUnHide) => columnToUnHide === column.columnId
          );
          return (
            <DropDownListItem
              checked={Boolean(isChecked)}
              column={column}
              onToggle={() => onItemToggle(column.columnId)}
              key={column.columnId}
            />
          );
        })}
      </List>
      <ConfirmButton
        isDisabled={isButtonDisabled}
        disabled={isButtonDisabled}
        onClick={onConfirm}
      >
        Confirm
      </ConfirmButton>
    </>
  );
};
