import * as React from 'react';
import { DropDownItemProps } from '../../types';
import { Item } from './DropDownItem.styles';

export const DropDownItem: React.FC<DropDownItemProps> = ({
  action,
  label,
  dataOnboarding,
}: DropDownItemProps) => {
  return (
    <Item data-onboarding={dataOnboarding} onClick={action}>
      {label}
    </Item>
  );
};
