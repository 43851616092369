import React from 'react';
import { OptionItem } from 'Client/types';
import { mapValuesToOptions } from 'Pages/dashboard/contributions/utils';
import CustomSelect from '../CustomSelect/CustomSelect';
import { ConditionContainerWrapper } from './styles';

interface ConditionContainerProps {
  rowIndex: number;
  openToTop: boolean;
  rowCondition: string;
  conditionOptions: OptionItem[];
  updateRowCondition: (rowIndex: number, newCondition: string) => void;
}

export default function ConditionContainer({
  rowIndex,
  conditionOptions,
  openToTop,
  rowCondition,
  updateRowCondition,
}: ConditionContainerProps) {
  return (
    <ConditionContainerWrapper data-gi="globalFiltersV2-condition-container">
      <CustomSelect
        openToTop={openToTop}
        options={conditionOptions}
        value={mapValuesToOptions([rowCondition])}
        onChange={({ value }) => updateRowCondition(rowIndex, value)}
      />
    </ConditionContainerWrapper>
  );
}
