import styled, { css } from 'styled-components';

export const Wrapper = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5.735rem;
  border-top: 1px solid rgba(151, 151, 151, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  button {
    border: none;
    cursor: pointer;
    padding: 0.625rem 1rem;
  }
`;

export const LeftSideButton = styled.button`
  background: transparent;
  font-size: 1rem;
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.semi};
    color: ${theme.colors.green[500]};
  `}
`;
export const RightSideButton = styled.button`
  font-size: 1.15rem;
  border-radius: 0.35rem;
  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.white[500]};
    background: ${theme.colors.green[500]};
  `}
`;
