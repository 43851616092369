import {
  GroupInfo,
  GroupReference,
  GroupFilters,
  GroupNestedFilters,
  FilterGroupCondition,
} from 'Shared/types/group';
import {
  ContributionsFilters,
  TopFilters,
  GlobalFilterItem,
} from 'Shared/types/dashboard';
import { MapDataToGroupInfoProps } from '../types';

export const mapTopFilters = (topFilters: TopFilters): TopFilters => {
  if (!topFilters) return undefined;
  const { sentiment, date, proposals } = topFilters;
  if (!date && !sentiment?.length && !proposals?.length) return undefined;
  return {
    date: date ? date : undefined,
    sentiment: sentiment?.length ? sentiment : undefined,
    proposals: proposals?.length ? proposals : undefined,
  };
};

export const mapNestedFilters = (
  nestedFilters: Array<GlobalFilterItem>
): Array<GroupNestedFilters> => {
  if (!nestedFilters || !nestedFilters?.length) return undefined;
  const mappedNestedFilters = nestedFilters.map((filter) => {
    const groupNestedFilters = {
      filterGroup: filter.filterGroup as FilterGroupCondition,
      filterProperty: filter.property,
      filterCondition: filter.filterCondition,
      filterValue: filter.value,
      ...(filter.questionId && { question: { questionId: filter.questionId } }),
    } as GroupNestedFilters;
    return groupNestedFilters;
  });
  return mappedNestedFilters;
};

const mapFilters = (filters: ContributionsFilters): GroupFilters => {
  if (!filters) return undefined;
  const { topFilters, nestedFilters } = filters;
  const mappedTopFilters = mapTopFilters(topFilters);
  const mappedNestedFilters = mapNestedFilters(nestedFilters);
  return {
    topFilters: mappedTopFilters,
    nestedFilters: mappedNestedFilters,
  };
};

export const mapDataToGroupInfo = ({
  projectId,
  groupName,
  groupType,
  filters,
}: MapDataToGroupInfoProps): GroupInfo => {
  const mappedFilters = mapFilters(filters);
  return {
    projectId,
    name: groupName,
    reference: GroupReference.CONTRIBUTION,
    type: groupType,
    filters: mappedFilters,
  };
};
