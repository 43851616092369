import * as React from 'react';
import { CircleCheckIcon, DownloadIcon } from 'Atoms/Icons';
import { StyledCheckbox } from 'Atoms';
import {
  CancelButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  ExportButton,
  HeaderOptions,
  HeaderTitle,
  ContentSection,
  ContentSectionTitle,
  ContentSectionText,
  ExportedContainer,
  ExportedTextContainer,
  ExportedTextIcon,
  ExportedText,
  ExportedImg,
  CloseHolder,
  StyledCloseIcon,
  ClearFiltersButton,
  InputWrapper,
} from './ExportModal.styles';
import { RadioButton } from './components/RadioButton/RadioButton';

export const ExportModal = (props) => {
  const {
    isOpen,
    onModalClose,
    onExport,
    exported,
    exporting,
    filters,
    pageType,
  } = props;

  const [addRespondents, setAddRespondents] = React.useState(false);
  const [clearFilters, setClearFilters] = React.useState(false);
  const [hasFilters, setHasFilters] = React.useState(false);

  const [exportStructure, setExportStructure] = React.useState('single');

  const closeModalActions = () => {
    onModalClose();
  };

  React.useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      setHasFilters(true);
    }
  }, [filters]);

  const handleFilters = () => {
    setClearFilters(true);
    setHasFilters(false);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth="sm"
      onClose={closeModalActions}
    >
      <DialogHeader>
        <HeaderTitle>
          {exported ? 'Your export is on its way' : 'Prepare your export'}
        </HeaderTitle>
        <HeaderOptions></HeaderOptions>
        <CloseHolder onClick={onModalClose}>
          <StyledCloseIcon />
        </CloseHolder>
      </DialogHeader>

      <DialogContent data-testid="export-dialog-content">
        {exported ? (
          <ExportedContainer>
            <ExportedTextContainer>
              <ExportedTextIcon>
                <CircleCheckIcon />
              </ExportedTextIcon>
              <ExportedText>
                {`Sit back. We'll email you your download when it's ready, this
                usually takes a minute or two`}
              </ExportedText>
            </ExportedTextContainer>
            <ExportedImg>
              <img
                src="/static/illustrations/dashboard_export_modal.png"
                width="328"
                height="293"
              />
            </ExportedImg>
          </ExportedContainer>
        ) : (
          <>
            <ContentSection data-testid="filters-section">
              <ContentSectionTitle>
                Your export will contain only the rows which match your filtered
                view
              </ContentSectionTitle>
              <ContentSectionText>
                <ClearFiltersButton
                  hasFilters={hasFilters}
                  onClick={() => handleFilters()}
                >
                  <StyledCloseIcon clearFilter hasFilters={hasFilters} />
                  Clear Filters
                </ClearFiltersButton>
              </ContentSectionText>
            </ContentSection>

            {pageType === 'contributions' && (
              <ContentSection data-testid="contributions-respondents-section">
                <ContentSectionTitle data-testid="contributions-respondents-section-text">
                  Your export will include respondents data
                </ContentSectionTitle>
                <ContentSectionText data-testid="include-respondents-data-option">
                  <InputWrapper>
                    <StyledCheckbox
                      type="checkbox"
                      checked={addRespondents}
                      onChange={() => setAddRespondents(!addRespondents)}
                    />
                    Include respondents data
                  </InputWrapper>
                </ContentSectionText>
              </ContentSection>
            )}
            {(pageType === 'contributions' || pageType === 'respondents') && (
              <ContentSection data-testid="choice-section">
                <ContentSectionTitle>Your export structure</ContentSectionTitle>
                <ContentSectionText
                  onChange={(e: React.BaseSyntheticEvent) =>
                    setExportStructure(e.target.value)
                  }
                >
                  <InputWrapper>
                    <RadioButton
                      selected={exportStructure === 'multicolumn'}
                      onClick={() => setExportStructure('multicolumn')}
                    />
                    I prefer multiple choice answers in multiple columns (best
                    for pivot tables)
                  </InputWrapper>
                  <InputWrapper>
                    <RadioButton
                      selected={exportStructure === 'single'}
                      onClick={() => setExportStructure('single')}
                    />
                    I prefer multiple-choice answers in one column (all answers
                    displayed in the same cell, separated by a comma)
                  </InputWrapper>
                </ContentSectionText>
              </ContentSection>
            )}
          </>
        )}
      </DialogContent>

      <DialogFooter exported={exported}>
        <CancelButton onClick={onModalClose}>
          {exported ? 'Close' : 'Cancel'}
        </CancelButton>
        {!exported && (
          <ExportButton
            data-onboarding="dashboard-export-modal"
            disabled={exporting}
            onClick={() =>
              onExport({
                clearFilters: clearFilters,
                includeHidden: true,
                addRespondents,
                singleWorksheet: true, // temp change
                exportStructure,
              })
            }
          >
            <DownloadIcon />
            Download
          </ExportButton>
        )}
      </DialogFooter>
    </Dialog>
  );
};
