import * as React from 'react';
import { RenderFilterValues } from '../RenderFilterValues';
import { getFiltersWithValue } from '../../utils';
import { FiltersDisplayerProps } from '../../types';
import {
  Wrapper,
  FiltersWrapper,
  FilterRow,
  FilterRowLabel,
  FilterRowValueContainer,
} from './FilterDisplayer.styles';

export const FiltersDisplayer: React.FC<FiltersDisplayerProps> = ({
  hasFilters,
  filters,
}: FiltersDisplayerProps) => {
  const filtersWithValue = getFiltersWithValue({ filters });
  if (!hasFilters) return <div data-testid="FiltersDisplayer-Wrapper" />;
  return (
    <Wrapper data-testid="FiltersDisplayer-Wrapper">
      <FiltersWrapper>
        {filtersWithValue.map((filter) => (
          <FilterRow key={filter.label}>
            <FilterRowLabel>{`${filter.label}:`}</FilterRowLabel>
            <FilterRowValueContainer>
              <RenderFilterValues value={filter.value} />
            </FilterRowValueContainer>
          </FilterRow>
        ))}
      </FiltersWrapper>
    </Wrapper>
  );
};
