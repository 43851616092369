import * as React from 'react';
import dynamic from 'next/dynamic';
import { PinColumnIcon } from 'Icons';
import { ToolTip } from 'Client/components/atoms';
import { NoOptionsPlaceholder } from '..';
import { HeaderDropDownProps } from '../../types';
import {
  Wrapper,
  Header,
  Body,
  HeaderTitle,
  IconsWrapper,
  PinWrapper,
  HideColumnWrapper,
} from './HeaderDropDown.styles';

const HiddenColumnsToggle = dynamic(() =>
  import('Pages/dashboard/contributions/components/HiddenColumnsToggle').then(
    (mod) => mod.HiddenColumnsToggle
  )
);

export const HeaderDropDown: React.FC<HeaderDropDownProps> = ({
  columnHeader,
  isPinned,
  isLastColumn,
  handlePin,
  handleHideColumn,
  handleMouseIn,
  handleMouseOut,
}: HeaderDropDownProps) => {
  const [isToggleChecked, setIsToggleChecked] = React.useState<boolean>(true);
  return (
    <Wrapper
      isLastColumn={isLastColumn}
      onMouseEnter={handleMouseIn}
      onMouseLeave={handleMouseOut}
      data-testid="HeaderDropDown-Wrapper"
    >
      <Header>
        <ToolTip
          align="left"
          toolTipText={!isPinned ? 'Pin column' : 'Unpin column'}
        >
          <PinWrapper
            data-testid="HeaderDropDown-PinWrapper"
            onClick={() => handlePin()}
          >
            <PinColumnIcon
              data-testid="HeaderDropDown-PinIcon"
              color={isPinned ? 'green' : 'black'}
            />
          </PinWrapper>
        </ToolTip>
        <HeaderTitle>{columnHeader}</HeaderTitle>
        <IconsWrapper>
          <ToolTip toolTipText="Hide column">
            <HideColumnWrapper
              data-onboarding="dashboard-hide-comments"
              data-testid="HeaderDropDown-HideColumnWrapper"
            >
              <HiddenColumnsToggle
                checked={isToggleChecked}
                onToggle={() => {
                  setIsToggleChecked(!isToggleChecked);
                  setTimeout(() => {
                    handleHideColumn();
                  }, 100);
                }}
              />
            </HideColumnWrapper>
          </ToolTip>
        </IconsWrapper>
      </Header>
      <Body>
        <NoOptionsPlaceholder />
      </Body>
    </Wrapper>
  );
};
