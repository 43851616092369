import * as React from 'react';
import { SummaryProps } from '../../types';
import { Wrapper } from './Summary.styles';

export const Summary: React.FC<SummaryProps> = ({
  hasFilters,
  contributionsCount,
  reference,
}: SummaryProps) => {
  return (
    <Wrapper data-testid="Summary-Wrapper">
      <span>
        You are grouping{' '}
        <strong>{`${contributionsCount} ${reference}s`}</strong>
        {hasFilters && ', fulfilling the following criteria'}
      </span>
    </Wrapper>
  );
};
