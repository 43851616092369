import * as React from 'react';
import { HiddenColumnsToggle } from 'Pages/dashboard/contributions/components';
import {
  Header,
  HeaderIconWrapper,
  HeaderTitleWrapper,
} from './DropDownHeader.styles';
import { DropDownHeaderProps } from '../../types';

export const DropDownHeader: React.FC<DropDownHeaderProps> = ({
  columnsToUnHide,
  totalHiddenColumns,
  toggleAll,
}: DropDownHeaderProps) => {
  const [checked, setChecked] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (columnsToUnHide.length === totalHiddenColumns && totalHiddenColumns) {
      setChecked(true);
      return;
    }
    if (columnsToUnHide.length < totalHiddenColumns && checked) {
      setChecked(false);
      return;
    }
  }, [columnsToUnHide]);

  return (
    <Header data-testid="DropDownHeader-Header">
      <HeaderIconWrapper data-testid="DropDownHeader-HeaderIconWrapper">
        {Boolean(totalHiddenColumns) && (
          <HiddenColumnsToggle
            checked={checked}
            onToggle={() => {
              setChecked(!checked);
              toggleAll(!checked);
            }}
          />
        )}
      </HeaderIconWrapper>
      <HeaderTitleWrapper>
        <span>{`${
          totalHiddenColumns - columnsToUnHide.length
        } hidden columns`}</span>
      </HeaderTitleWrapper>
    </Header>
  );
};
