import { OptionItem } from 'Client/types';

export const mapValuesToOptions = (
  values: string[] | number[] | OptionItem[]
): OptionItem[] => {
  return values.map((value) => {
    if (typeof value === 'object') {
      return { value: value.value, label: value.label };
    }

    return { value, label: value as string };
  });
};
