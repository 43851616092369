import * as React from 'react';
import { ColumnsDropDownProps } from '../../types';
import { DropDownHeader, DropDownList } from '..';
import { Wrapper } from './ColumnsDropDown.styles';

export const ColumnsDropDown: React.FC<ColumnsDropDownProps> = ({
  hiddenColumns,
  setHiddenColumns,
  closeDropDown,
}: ColumnsDropDownProps) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [columnsToUnHide, setColumnsToUnHide] = React.useState<Array<string>>(
    []
  );

  const toggleAll = (isChecked: boolean) => {
    if (isChecked) {
      const newColumnsToUnHide = hiddenColumns.map((column) => column.columnId);
      setColumnsToUnHide(newColumnsToUnHide);
      return;
    }
    setColumnsToUnHide([]);
  };

  const onItemToggle = (columnId: string): void => {
    const isAlreadyAdded = columnsToUnHide.findIndex(
      (column) => column === columnId
    );
    if (isAlreadyAdded === -1) {
      setColumnsToUnHide([...columnsToUnHide, columnId]);
      return;
    }
    const newColumns = columnsToUnHide.filter((column) => column !== columnId);
    setColumnsToUnHide(newColumns);
  };

  const onConfirm = (): void => {
    const newHiddenColumns = hiddenColumns.filter((hiddenColumn) => {
      const { columnId } = hiddenColumn;
      return !columnsToUnHide.includes(columnId);
    });
    setHiddenColumns(newHiddenColumns);
    closeDropDown();
  };

  React.useEffect(() => {
    function onClickOutsideModal(event) {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        closeDropDown();
      }
    }
    document.addEventListener('mousedown', onClickOutsideModal);
    return () => {
      document.removeEventListener('mousedown', onClickOutsideModal);
    };
  }, [wrapperRef]);

  return (
    <Wrapper data-testid="ColumnsDropDown-Wrapper" ref={wrapperRef}>
      <DropDownHeader
        columnsToUnHide={columnsToUnHide}
        totalHiddenColumns={hiddenColumns.length}
        toggleAll={toggleAll}
      />
      {Boolean(hiddenColumns.length) && (
        <DropDownList
          columnsToUnHide={columnsToUnHide}
          hiddenColumns={hiddenColumns}
          onConfirm={onConfirm}
          onItemToggle={onItemToggle}
        />
      )}
    </Wrapper>
  );
};
