import { FilterOption, FilterRow } from 'Shared/types/filters';
import defaultConditionValue from './defaultConditionValue';
import getAvailableAnswers from './getAvailableAnswers';
import mapRowMetadata from './mapRowMetadata';

interface Props {
  currentRow: FilterRow;
  newProperty: string;
  filterOptions: FilterOption[];
  additionalFilterable: { [key: string]: string[] };
  allAnswers: { [key: string]: unknown[] };
}

export default function updateProperty({
  currentRow,
  newProperty,
  filterOptions,
  additionalFilterable,
  allAnswers,
}: Props): FilterRow {
  const filterOption = filterOptions.find((option) => {
    return option.property === newProperty;
  });
  if (!filterOption) return currentRow;
  const { rules, conditions } = filterOption;
  const { conditionConfig } = rules;
  const initialCondition = conditions[0];
  const inputType = conditionConfig[initialCondition];
  const metadata = mapRowMetadata(filterOption);
  const possibleValues = getAvailableAnswers({
    filterOption,
    additionalFilterable,
    allAnswers,
    rowMetadata: metadata,
  });
  const value = defaultConditionValue({
    condition: initialCondition,
    inputType,
  });
  return {
    ...currentRow,
    property: newProperty,
    condition: initialCondition,
    possibleConditions: conditions,
    possibleValues,
    inputType,
    value,
    metadata,
  };
}
