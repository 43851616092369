import styled, { css } from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog';

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    padding: 2rem 2.2rem;
    overflow: auto;
    position: absolute;
    max-width: none;
    min-height: 55%;
    max-height: 70%;
  }
`;

export const DialogContent = styled.div`
  display: flex;
  flex: 2;
  margin: 1rem 0;
  flex-flow: column;
  justify-content: center;
`;

export const DialogFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 1rem;
`;

export const ApplyFiltersButton = styled.button`
  font-size: 1.15rem;
  font-weight: bold;
  border: none;
  padding: 0.5rem 0.4rem;
  border-radius: 0.35rem;
  ${({ theme, disabled }) => css`
    color: ${theme.colors.white[500]};
    background: ${disabled ? theme.colors.grey[200] : theme.colors.green[500]};
    cursor: ${disabled ? 'default' : 'pointer'};
  `}
`;
