import * as React from 'react';
import { FooterRowProps } from '../../types';
import {
  SummaryFooterRow,
  SummaryHeaderDivider,
  SummaryFooterItemWrapper,
  SummaryFooterInfo,
  SummaryFooterLabel,
  AgreementIconWrapper,
} from './FooterRow.styles';

const DividerComponent = () => <SummaryHeaderDivider>|</SummaryHeaderDivider>;

export const FooterRow = ({ footerData }: FooterRowProps) => {
  return (
    <SummaryFooterRow data-testid="footerInfo">
      {footerData.map((data, index) => {
        const key = `${data.label}-${index}`;
        if (data.label === 'divider') return <DividerComponent key={key} />;
        return (
          <SummaryFooterItemWrapper key={key}>
            {data.label === 'Agreements' ? (
              <AgreementIconWrapper>{data.icon}</AgreementIconWrapper>
            ) : (
              <>{data.icon}</>
            )}
            <SummaryFooterInfo>{data.value}</SummaryFooterInfo>
            <SummaryFooterLabel>{data.label}</SummaryFooterLabel>
          </SummaryFooterItemWrapper>
        );
      })}
    </SummaryFooterRow>
  );
};
