import { getDaysInMonth, isSameDay } from 'date-fns';
import { months } from './const';
import { MonthProps } from './types';

type isSelectedDateProps = {
  day: number;
  dateTo: Date;
  calendarMonth: MonthProps;
  dateFrom?: Date;
};

export const getMonthDataOfDate = (date: Date): MonthProps => {
  const monthNumber = date.getMonth();
  const year = date.getFullYear();
  const name = months[monthNumber];

  const daysInMonth = getDaysInMonth(date);

  const firstDay = new Date(year, monthNumber);
  const weekFirstDay = firstDay.getDay();

  return {
    name,
    year,
    monthNumber,
    daysInMonth,
    weekFirstDay,
  };
};

export const isSelectedDate = ({
  day,
  dateFrom,
  dateTo,
  calendarMonth,
}: isSelectedDateProps): boolean => {
  if (!day) return false;

  const newDate = new Date(calendarMonth.year, calendarMonth.monthNumber, day);

  return isSameDay(newDate, dateFrom) || isSameDay(newDate, dateTo);
};

export const isBetweenDates = ({
  day,
  dateFrom,
  dateTo,
  calendarMonth,
}: isSelectedDateProps): boolean => {
  if (!day) return false;

  const newDate = new Date(calendarMonth.year, calendarMonth.monthNumber, day);

  return newDate > dateFrom && newDate < dateTo;
};

export const isToDate = ({
  day,
  dateTo,
  calendarMonth,
}: isSelectedDateProps): boolean => {
  if (!day) return false;

  const newDate = new Date(calendarMonth.year, calendarMonth.monthNumber, day);

  return isSameDay(newDate, dateTo);
};

export const timezoneOffsetRemoval = (date: Date) => {
  console.log(date, 'time');
  if (!date || Number.isNaN(new Date(date).getTime())) {
    return;
  }

  const castDate = new Date(date);
  const timezoneOffset = castDate.getTimezoneOffset() * 60000;
  console.log(castDate.getTimezoneOffset());
  return new Date(castDate.getTime() - timezoneOffset);
};
