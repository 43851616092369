import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0 10%;
  justify-content: center;
  border-bottom: 0.1rem solid rgba(151, 151, 151, 0.4);
`;

export const HeaderIconWrapper = styled.div`
  margin-right: 0.4rem;
`;

export const HeaderTitleWrapper = styled.div`
  span {
    opacity: 0.77;
    font-weight: 700;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.black[500]};
  }
`;
