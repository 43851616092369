import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  p {
    margin: 0;
    font-size: 0.875rem;
    opacity: 0.8;
    margin-left: 2rem;
    ${({ theme }) => css`
      font-weight: ${theme.fontWeights.normal};
      color: ${theme.colors.black[400]};
    `}
  }
  span {
    font-size: 1rem;
  }
`;

export const RadioItem = styled.div<{ isGroupSelected: boolean }>`
  margin-top: 1rem;
  ${({ theme, isGroupSelected }) =>
    isGroupSelected
      ? css`
          button {
            color: ${theme.colors.green[500]};
          }
          span {
            color: ${theme.colors.black[500]};
            font-weight: ${theme.fontWeights.semi};
          }
        `
      : css`
          button,
          span {
            color: ${theme.colors.grey[900]};
          }
        `}
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const RadioButton = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  svg {
    height: inherit;
    width: inherit;
  }
`;
