import * as React from 'react';
import { components } from 'react-select';
import { CalendarIcon } from 'Icons';
import { SPECIFY_DATES } from '../../predefinedOptions';
import {
  ApplyFilterButton,
  CalendarOptionWrapper,
} from './selectComponents.styles';

export const customMenuComponent = ({ onApplyFilters }) => {
  const MenuComponent = (props) => (
    <>
      <components.Menu {...props}>
        {props.children}
        <ApplyFilterButton onClick={onApplyFilters}>
          Apply filter
        </ApplyFilterButton>
      </components.Menu>
    </>
  );
  return MenuComponent;
};

export const customOptionComponent = () => {
  const OptionComponent = (props) => (
    <>
      {props.data.label === SPECIFY_DATES ? (
        <CalendarOptionWrapper>
          <components.Option {...props}>
            <CalendarIcon />
            {props.data.label}
          </components.Option>
        </CalendarOptionWrapper>
      ) : (
        <components.Option {...props} />
      )}
    </>
  );
  return OptionComponent;
};
