import { NULL } from 'Shared/constants';
import { InputType } from 'Shared/types/filters';

interface DefaultConditionValueProps {
  condition: string;
  inputType: InputType;
}

export default function defaultConditionValue({
  condition,
  inputType,
}: DefaultConditionValueProps) {
  if (inputType === 'select') return [];
  if (inputType === 'textfield') return '';
  if (inputType === 'numericField') return 0;
  if (inputType === 'booleanField') return condition === NULL;
  // a default value that all should handle
  return [];
}
