import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { FilterIcon, UploadIcon } from 'Icons';
import { TabNavigationProps } from './types';
import {
  Wrapper,
  TabItem,
  OptionsWrapper,
  OptionsButtons,
  ManageGroupsButton,
} from './TabNavigation.styles';
import { redirectToSliderTab } from '../../utils/tabUrlHelpers';

export const TabNavigation: React.FC<TabNavigationProps> = ({
  selectedTab,
  tabOptions,
  onTabChange,
  onFilterClick,
  isExportEnabled,
  isNewTopicEnabled,
  onExportClick,
  setIsSidePanelOpen,
  setRenderGroupManagement,
}: TabNavigationProps) => {
  const router = useRouter();
  const { t } = useTranslation('customer');

  const [isOnTopicsTab, setIsOnTopicsTab] = React.useState<boolean>(false);

  React.useEffect(() => {
    const { tab } = router.query;

    if (tab === 'topics') {
      setIsOnTopicsTab(true);

      return;
    }

    setIsOnTopicsTab(false);
  }, [router.query]);

  return (
    <Wrapper data-onboarding="dashboard-section-tabs">
      <div data-testid="tab-items-wrapper">
        {tabOptions.map((item) => (
          <TabItem
            isSelected={item.value === selectedTab}
            key={`${item.label}`}
            onClick={() => onTabChange(item.id)}
          >
            {item.label}
          </TabItem>
        ))}
      </div>
      <OptionsWrapper data-onboarding="dashboard-table-filters">
        {isOnTopicsTab && (
          <ManageGroupsButton
            onClick={() => {
              setIsSidePanelOpen(true);
              setRenderGroupManagement(true);
            }}
          >
            <span>{t('Edit categorisation')}</span>
          </ManageGroupsButton>
        )}
        {isNewTopicEnabled && (
          <OptionsButtons
            data-onboarding="dashboard-nested-filter"
            onClick={() => {
              redirectToSliderTab({
                newTab: 'newTopic',
                page: 'trends-ai',
                router,
              });
            }}
          >
            <div
              onClick={() =>
                redirectToSliderTab({
                  newTab: 'newTopic',
                  page: 'trends-ai',
                  router,
                })
              }
            >
              + New topic
            </div>
          </OptionsButtons>
        )}
        {isExportEnabled && (
          <OptionsButtons
            data-onboarding="dashboard-export"
            data-testid="TabNavigation-export-button"
            onClick={onExportClick}
          >
            <UploadIcon />
            <span>Export</span>
          </OptionsButtons>
        )}
        <OptionsButtons
          data-onboarding="dashboard-nested-filter"
          onClick={onFilterClick}
        >
          <FilterIcon />
          <span>Filter</span>
        </OptionsButtons>
      </OptionsWrapper>
    </Wrapper>
  );
};
