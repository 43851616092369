import * as React from 'react';
import { CancelIcon } from 'Icons';
import { FilterRow, FilterOption } from 'Shared/types/filters';
import { Wrapper, DeleteRowButton } from './Row.styles';
import RowLayout from '../RowElements/RowLayout';

interface RowProps {
  row: FilterRow;
  filterOptions: FilterOption[];
  rowIndex: number;
  openToTop: boolean;
  deleteRow: () => void;
  updateRowGroup: (rowIndex: number, newGroup: string) => void;
  updateRowProperty: (rowIndex: number, newProperty: string) => void;
  updateRowCondition: (rowIndex: number, newCondition: string) => void;
  updateRowValue: (rowIndex: number, newValue: string) => void;
  updateRowMetadata: (
    rowIndex: number,
    keyToUpdate: string,
    newValue: string
  ) => void;
}

export default function Row(props: RowProps) {
  const { deleteRow, ...additionalProps } = props;

  return (
    <Wrapper>
      <DeleteRowButton
        onClick={deleteRow}
        data-gi="globalFiltersV2-delete-row-btn"
      >
        <CancelIcon />
      </DeleteRowButton>
      <RowLayout {...additionalProps} />
    </Wrapper>
  );
}
