import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
  svg {
    height: 0.7rem;
    width: 0.7rem;
    opacity: 0.65;
  }
`;
