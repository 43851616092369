import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EyeWithLineIcon } from 'Icons';
import { ColumnsDropDown } from './components/';
import { TableHeaderProps } from './types';
import {
  Wrapper,
  LeftSideLabel,
  ActionsWrapper,
  HiddenColumnsButton,
} from './TableHeader.styles';

export const TableHeader: React.FC<TableHeaderProps> = ({
  currentlyFilteredRows,
  dataTotal,
  isTableLoading,
  hiddenColumns,
  setHiddenColumns,
  dataName,
}: TableHeaderProps) => {
  const { t } = useTranslation();
  const [isDropDownOpen, setIsDropDownOpen] = React.useState(false);
  if (isTableLoading) return null;
  return (
    <Wrapper data-testid="TableHeader-Wrapper">
      <LeftSideLabel>
        {t('Showing {{filteredRows}} {{dataName}} out of {{dataTotal}}', {
          filteredRows: currentlyFilteredRows,
          dataName,
          dataTotal,
        })}
      </LeftSideLabel>
      <ActionsWrapper>
        {setHiddenColumns && (
          <HiddenColumnsButton
            onClick={() => setIsDropDownOpen(!isDropDownOpen)}
            data-testid="TableHeader-HiddenColumnsButton"
          >
            <EyeWithLineIcon />
            <span>{t('Show/hide columns')}</span>
          </HiddenColumnsButton>
        )}
        {isDropDownOpen && (
          <ColumnsDropDown
            hiddenColumns={hiddenColumns}
            setHiddenColumns={setHiddenColumns}
            closeDropDown={() => setIsDropDownOpen(false)}
          />
        )}
      </ActionsWrapper>
    </Wrapper>
  );
};
