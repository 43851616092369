import styled, { css } from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog';
import { CloseIcon } from 'Atoms/Icons';
import { StyledCloseIconProps } from './types';

export const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    overflow: auto;
    bottom: 0;
    right: 0;
    max-width: 50%;
  }
`;

export const DialogFooter = styled.div<{ exported: boolean }>`
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 2rem 2rem;
  ${({ exported }) => exported && `border-top: 0.1rem solid #e5e5e5;`}
`;

export const ExportButton = styled.button`
  font-size: 1.15rem;
  font-weight: bold;
  border: none;
  padding: 1rem 1rem;
  border-radius: 0.35rem;
  display: flex;
  align-items: center;
  ${({ theme, disabled }) => css`
    color: ${theme.colors.white[500]};
    background: ${disabled ? theme.colors.grey[200] : theme.colors.green[500]};
    cursor: ${disabled ? 'default' : 'pointer'};
  `}
  svg {
    margin-right: 0.5rem;
  }
`;
export const CancelButton = styled.button`
  font-size: 1.15rem;
  line-height: 1.75rem;
  font-weight: bold;
  border: 1px solid #979797;
  padding: 0.75rem 1.5rem;
  border-radius: 0.35rem;
  margin-right: 1.2rem;
  ${({ theme, disabled }) => css`
    border: ${disabled ? 'none' : '1px solid #979797'};
    color: ${disabled ? theme.colors.white[500] : 'black'};
    background: ${disabled ? theme.colors.grey[200] : 'white'};
    cursor: ${disabled ? 'default' : 'pointer'};
  `}
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.white[600]};
  padding: 1.5rem 2rem;
`;

export const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #303030;
`;

export const HeaderOptions = styled.div`
  display: flex;
  button {
    cursor: pointer;
  }
`;

export const DialogContent = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  flex-direction: column;
  flex: 2;
  justify-content: start;
`;

export const ContentSection = styled.div`
  padding: 0 2.5rem 0.75rem;
  border-bottom: 0.1rem solid #d8d8d8;
  margin: 1.2rem 0rem 0rem;
`;

export const ContentSectionTitle = styled.div`
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 2rem;
`;

export const ContentSectionText = styled.div`
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  display: flex;
  flex-direction: column;

  p {
    font-style: italic;
    font-size: 0.8rem;
    margin: 0;
    padding: 0 2rem;
    color: black;
  }
`;

export const InputWrapper = styled.div<{ disableRaw?: boolean }>`
  display: flex;
  margin-bottom: 1.2rem;

  ${({ disableRaw }) =>
    disableRaw &&
    `
  filter: grayscale(1);
  margin-bottom: 0;`}
`;

export const ExportedContainer = styled.div``;

export const ExportedTextContainer = styled.div`
  background: #e7fff4;
  padding: 1.5rem;
  display: flex;
  border-left: 0.4rem solid #00ac5d;
  align-items: end;
  margin: 1.8125rem 2.3125rem;
`;

export const ExportedTextIcon = styled.div`
  margin-right: 0.5rem;

  svg {
    stroke: #176d46;
    stroke-width: 1;
  }
`;

export const ExportedText = styled.div`
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #176d46;
`;
export const ExportedImg = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 7%;
`;
export const CloseHolder = styled.div`
  cursor: pointer;
`;

export const StyledCloseIcon = styled(CloseIcon).attrs<StyledCloseIconProps>(
  ({ hasFilters, clearFilter }) => ({
    color: clearFilter && !hasFilters ? 'black' : '#00AC5D',
    strokeWidth: 1.5,
  })
)<StyledCloseIconProps>``;

export const ClearFiltersButton = styled.button<{ hasFilters: boolean }>`
  background: ${(props) =>
    props.hasFilters ? 'rgb(0 172 93 / 10%)' : '#e5e5e5'};
  color: ${(props) => (props.hasFilters ? '#00AC5D' : 'black')};
  cursor: pointer;
  border: none;
  width: 8.125rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: ${(props) => (props.hasFilters ? 700 : 400)};
  display: flex;
  align-items: center;
  svg {
    stroke-width: 1;
    width: 0.65rem;
    height: 0.65rem;
    margin-right: 0.5rem;
    margin-bottom: 0.1rem;
  }
`;
