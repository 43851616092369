import * as React from 'react';
import format from 'date-fns/format';
import { DateRange } from 'Shared/types/dashboard';
import { RenderFilterValuesProps } from '../../types';
import { TextWrapper } from './RenderFilterValues.styles';

export const RenderFilterValues: React.FC<RenderFilterValuesProps> = ({
  value,
}: RenderFilterValuesProps) => {
  const [text, setText] = React.useState<string | number | boolean | DateRange>(
    ''
  );

  const firstCheck = (value: DateRange) => {
    //checks for date filter
    const from = value.from ? (new Date(value.from) as Date) : null;
    const to = value.to ? (new Date(value.to) as Date) : null;
    if (from === null && to === null) {
      setText('All time');
      return;
    }
    if (from || to) {
      const pattern = 'dd/MM/yyyy';
      secondCheck(from, to, pattern);
      return;
    }
  };

  const secondCheck = (
    from: number | Date,
    to: number | Date,
    pattern: string
  ) => {
    if (from && to) {
      setText(`${format(from, pattern)} - ${format(to, pattern)}`);
      return;
    }
    if (from) {
      setText(`${format(from, pattern)}`);
      return;
    }
    setText(`${format(to, pattern)}`);
  };

  React.useEffect(() => {
    if (Array.isArray(value)) {
      const valueSeparatedByComma = value.join(', ');
      setText(valueSeparatedByComma);
      return;
    }
    if (typeof value === 'object') {
      firstCheck(value);
      return;
    }
    setText(value);
  }, [value]);

  return (
    <TextWrapper data-testid="RenderFilterValues-TextWrapper">
      {text}
    </TextWrapper>
  );
};
