import { FilterOption, FilterRow } from 'Shared/types/filters';
import defaultConditionValue from './defaultConditionValue';

interface Props {
  currentRow: FilterRow;
  newCondition: string;
  filterOptions: FilterOption[];
}

export default function updateCondition({
  currentRow,
  filterOptions,
  newCondition,
}: Props): FilterRow {
  const filterOption = filterOptions.find((option) => {
    return option.property === currentRow.property;
  });
  if (!filterOption) return currentRow;
  const { rules } = filterOption;
  const { conditionConfig } = rules;
  const inputType = conditionConfig[newCondition];
  const value = defaultConditionValue({ condition: newCondition, inputType });
  return {
    ...currentRow,
    condition: newCondition,
    inputType,
    value,
  };
}
