import styled, { css } from 'styled-components';

export const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const HeaderTitle = styled.div<{
  isSelected: boolean;
  isSorted: boolean;
  isChildColumn: boolean;
}>`
  flex: 1;
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  ${({ isChildColumn }) =>
    !isChildColumn &&
    `
  padding-left: 2.625rem;
   `}
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      cursor: pointer;
      font-weight: 700;
      color: ${theme.colors.black[500]};
    `};
  ${({ isSorted, theme }) =>
    isSorted &&
    css`
      font-weight: 700;
      color: ${theme.colors.green[500]};
    `};
`;
export const HeaderSort = styled.div`
  cursor: pointer;
  flex: 0;
  margin: 0.2rem 0.6rem;
  svg {
    color: ${({ theme }) => theme.colors.black[400]};
  }
`;
export const HeaderPin = styled.div`
  cursor: pointer;
  :hover {
    cursor: pointer;
  }
  svg {
    fill: black;
  }
`;

export const TableHeader = styled.div<{ isChildColumn: boolean }>`
  white-space: nowrap;
  padding: 0.5rem;
  text-align: left;
  position: sticky;
  font-weight: 600;
  padding: 0.625rem;
  ${({ theme, isChildColumn }) => css`
    background: ${theme.colors.white[500]};
    ${isChildColumn
      ? css`
          color: rgb(125, 125, 125);
          text-align: center;
          border-bottom: ${`1px solid ${theme.colors.grey[600]}`};
          border-left: ${({ theme }) => `thin solid ${theme.colors.grey[200]}`};
          border-top: ${({ theme }) => `thin solid ${theme.colors.grey[200]}`};
        `
      : css`
          color: rgb(64, 64, 64);
        `}
  `}
`;

export const TableResizer = styled.div`
  width: 0.2rem;
  height: 100%;
  border: 0.1rem solid;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%);
  z-index: 1;
  color: transparent;
  &:hover {
    color: ${({ theme }) => theme.colors.blue['A200']};
    background: ${({ theme }) => theme.colors.blue['A200']};
  }
`;
